import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useImperativeHandle, useState } from "react";
import { useNotify } from "react-admin";
import { makeCall } from "../dataProvider/makeCall";
import { ReactComponent as PhoneCallIconBlack } from "../images/ic-phone-black.svg";
import { ReactComponent as PhoneCallIconBlueMedium } from "../images/ic-phone-blue-medium.svg";
import { ReactComponent as PhoneCallIconWhite } from "../images/ic-phone-white.svg";

export type PhoneCallButtonRef = {
    makePhoneCall: () => void
}

const PhoneCallButton = React.forwardRef(({phoneNumber, variant}: PhoneCallButtonProps, ref) => {
    const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
    const handlePopoverOpen: React.MouseEventHandler<SVGSVGElement> = event => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose: React.MouseEventHandler = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    useImperativeHandle(ref, () => ({makePhoneCall})); // makePhoneCall callable from parent

    const notify = useNotify();
    const makePhoneCall = () => {
        const clientId = sessionStorage.getItem("workstationId");
        if (clientId !== null) {
            makeCall(phoneNumber, clientId)
                .then(_ => {
                    notify("da.phone.connection.start", {type: "info", autoHideDuration: 3000});
                })
                .catch(_ => {
                    notify("da.phone.connection.failed", {type: "warning"});
                });
        } else {
            console.error("Workstation id missing");
        }
    };

    const PhoneCallIcon = phoneCallIcon[variant];

    return (
        <Box
            sx={{cursor: open ? "pointer" : "default"}}
        >
            <PhoneCallIcon
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                width={20}
                height={20}
            />
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none"
                }}
                PaperProps={{
                    sx: {
                        padding: "0.25rem"
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{phoneNumber}</Typography>
            </Popover>
        </Box>
    );
});

const phoneCallIcon = {
    "black": PhoneCallIconBlack,
    "white": PhoneCallIconWhite,
    "blue-medium": PhoneCallIconBlueMedium
};

export type PhoneCallButtonProps = { phoneNumber: string, variant: "black" | "white" | "blue-medium" };

export default PhoneCallButton;
