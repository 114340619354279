import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { filter, isEmpty, join } from "lodash-es";
import * as React from "react";
import { useRef, useState } from "react";
import { usePermissions } from "react-admin";
import { PHONE_AUTHORITY } from "../authProvider/authProvider";
import { daBlueLight } from "../layout/Theme";
import ConfirmationTypeIcon from "./ConfirmationTypeIcon";
import PhoneCallButton, { PhoneCallButtonRef } from "./PhoneCallButton";
import { VSCrewMember } from "./VehicleStatuses";

const CrewMemberCard: React.FC<CrewMemberCardProps> = ({crewMember, perRole}) => {
    const [hover, setHover] = useState(false);

    const crewMemberName = join(
        filter([crewMember.firstName, crewMember.lastName], s => !isEmpty(s)),
        " "
    );

    const {permissions} = usePermissions();
    const hasPhonePermission = permissions?.includes(PHONE_AUTHORITY);

    const phoneCallButtonRef = useRef({} as PhoneCallButtonRef);
    const handleClick = () =>
        hasPhonePermission && phoneCallButtonRef.current?.makePhoneCall();

    return (
        <Box
            key={crewMember.shiftId}
            sx={{
                margin: "0.25rem 0rem",
                padding: "0rem 0.25rem",
                display: "grid",
                gridTemplateAreas: "'confirm name phone' '. role .'",
                gridTemplateColumns: "1.5rem 1fr 1.5rem",
                gridColumnGap: "0.5rem",
                gridTemplateRows: "minmax(2rem, 1fr) minmax(1.25rem, auto)",
                border: "2px solid transparent",
                borderRadius: "5px",
                "&:hover": {
                    border: `2px solid ${daBlueLight}`
                }
            }}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleClick}
        >
            <Box
                display="grid"
                justifyItems="center"
                alignItems="center"
                gridArea="confirm"
            >
                <ConfirmationTypeIcon
                    confirmationType={crewMember.confirmationType}
                />
            </Box>
            <Box
                display="grid"
                justifyItems="start"
                alignItems="center"
                gridArea="name"
            >
                <Typography variant="h5">{crewMemberName}</Typography>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyItems="start"
                alignItems="center"
                gridArea="role"
            >
                <Typography
                    variant="caption"
                    sx={{
                        marginRight: "0.5rem"
                    }}
                >
                    {crewMember.roleTitle}
                </Typography>
                {(crewMember.roleId === 1 || crewMember.roleId === 2) && (
                    <Typography
                        variant="caption"
                        sx={{
                            marginRight: "0.5rem",
                            fontWeight: "bold"
                        }}
                    >
                        {crewMember.crewLimit}
                    </Typography>
                )}
                {perRole && perRole > 1 && (
                    <Typography variant="subtitle2">
                        +{perRole - 1}
                    </Typography>
                )}
            </Box>
            <Box
                display="grid"
                justifyItems="center"
                alignItems="center"
                gridArea="phone"
            >
                {hasPhonePermission && hover && crewMember.phoneNumber && (
                    <PhoneCallButton
                        ref={phoneCallButtonRef}
                        phoneNumber={crewMember.phoneNumber}
                        variant="black"
                    />
                )}
            </Box>
        </Box>
    );
};

type CrewMemberCardProps = {
    crewMember: VSCrewMember;
    perRole?: number;
};

export default CrewMemberCard;
