import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { LayoutProps, Notification } from "react-admin";
import CustomSidebar from "./CustomSidebar";

const CustomLayout: React.FC<LayoutProps> = ({children}) => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            zIndex: 1,
            minHeight: "100vh",
            backgroundColor: theme.palette.background.default,
            position: "relative",
            minWidth: "fit-content",
            width: "100%",
            color: theme.palette.getContrastText(
                theme.palette.background.default
            )
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexGrow: 0
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "1rem 0rem 1rem 1rem"
                        }}
                    >
                        <CustomSidebar/>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            flexBasis: 0,
                            margin: "1rem"
                        }}
                    >
                        {children}
                    </Box>
                </Box>
                <Notification/>
            </Box>
        </Box>
    );
};

export default CustomLayout;
