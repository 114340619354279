import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useLogout, useSidebarState, useTranslate } from "react-admin";
import { ReactComponent as LogoutIcon } from "../images/ic-web-logout.svg";
import { daRedDark, daRedRegular } from "./Theme";

const LogoutButton = () => {
    const logout = useLogout();
    const translate = useTranslate();
    const theme = useTheme();

    const [open] = useSidebarState();
    const [hover, setHover] = useState(false);

    const fillColor = hover ? daRedDark : daRedRegular;

    const text = translate("ra.auth.logout");
    return (
        <MenuItem
            sx={{
                ...theme.typography.h4,
                color: daRedRegular,
                "&:hover": {
                    color: daRedDark
                }
            }}
            component="a"
            disableGutters={true}
            onClick={logout}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
            <Box
                sx={{
                    minWidth: "3rem",
                    marginRight: "0.5rem"
                }}
            >
                <LogoutIcon fill={fillColor}/>
            </Box>
            {open && text}
        </MenuItem>
    );
};

export default LogoutButton;
