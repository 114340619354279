import { get } from "lodash-es";
import * as React from "react";
import { Link, useRecordContext } from "react-admin";

type RecordLinkFieldProps = {
    source: string;
    linkResource: string;
    idProp?: string;
};

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: { stopPropagation: () => void; }) => e.stopPropagation();

const RecordLinkField: React.FC<RecordLinkFieldProps> =
    ({source, linkResource, idProp}) => {
        const record = useRecordContext();
        return record && <Link
            to={`/${linkResource}/${get(record, idProp ?? "id")}/show`}
            onClick={stopPropagation}
        >
            {get(record, source)}
        </Link>;
    };

export default RecordLinkField;
