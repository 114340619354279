import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useSidebarState } from "react-admin";
import { SidebarToggleButton } from "./SidebarToggleButton";

const AppBar = () => {
    const [open] = useSidebarState();
    return (
        <Toolbar variant="regular" disableGutters={true}
                 sx={{
                     justifyContent: "start",
                     minHeight: "4.25rem",
                     minWidth: "3rem",
                     backgroundColor: "transparent",
                     margin: 0
                 }}
        >
            <SidebarToggleButton/>
            {open && <Typography variant="h1" color="inherit" sx={{
                textOverflow: "ellipsis",
                fontWeight: 700,
                whiteSpace: "nowrap",
                overflow: "hidden"
            }}>
                Rega DA
            </Typography>}
        </Toolbar>
    );
};

export default AppBar;
