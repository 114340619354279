import Chip from "@mui/material/Chip";
import { get } from "lodash-es";
import * as React from "react";
import { useRecordContext } from "react-admin";
import { isMissionLocation, MissionLocation } from "../resources/MissionInfoStats";

export const LocationChipField: React.FC<{ source: string }> = ({source}) => {
    const record = useRecordContext();
    const location = source && get(record, source) as MissionLocation;

    if (isMissionLocation(location)) {
        const locationString = `${location.lat}, ${location.lon}`;
        return (
            <Chip
                sx={{margin: 4, cursor: "inherit"}}
                label={locationString}
            />
        );
    }
    return null;
};

export default LocationChipField;
