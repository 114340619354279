import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import * as StompJS from "@stomp/stompjs";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLogout, useNotify, useTranslate } from "react-admin";
import { API_BASE_URL, httpClient } from "../dataProvider/dataProvider";
import { daRedRegular, daWhite } from "../layout/Theme";
import useStompWS from "../stompWebsocket/StompWs";
import { VehicleStatusDTO, VehicleStatuses, VSHelicopter, VSRole, VSUser } from "./VehicleStatuses";

const WEBSOCKET_URL = process.env.REACT_APP_API_URL?.replace(/^http/, "ws") + "/v1/live";

const Dashboard = () => {
    const translate = useTranslate();
    const logout = useLogout();
    const notify = useNotify();

    const [vehicleStatuses, setVehicleStatuses] = useState(emptyVehicleStatusHolder.vehicleStatuses);
    const [helicopters, setHelicopters] = useState(emptyHelicoptersHolder.helicopters);
    const [roles, setRoles] = useState(emptyRolesHolder.roles);
    const [users, setUsers] = useState(emptyUsersHolder.users);

    const [connected, setConnected] = useState(false);
    const [showDisconnectBanner, setShowDisconnectBanner] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            httpClient(`${API_BASE_URL}/web/heartbeat`).then(() => console.debug("heartbeat returned OK")).catch(({status}) => {
                if (status === 401 || status === 403) {
                    clearInterval(interval);
                    logout().then(() => {
                        console.warn("heartbeat returned " + status + " => force logout");
                        notify("ra.notification.logged_out", {type: "warning"});
                    });
                }
            });
        }, 10000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [logout, notify]);

    const timerRef: React.MutableRefObject<NodeJS.Timeout> = useMemo(() => ({current: undefined as unknown as NodeJS.Timeout}), []);
    const delTimeout = useCallback(() => clearTimeout(timerRef.current), [timerRef]);
    useEffect(() => {
        timerRef.current && delTimeout();
        if (connected) {
            setShowDisconnectBanner(false);
        } else {
            timerRef.current = setTimeout(() => setShowDisconnectBanner(true), 5000);
        }
        return delTimeout;
    }, [timerRef, delTimeout, connected]);

    const onErrorMessage = (m: StompJS.Message) => {
        const message = JSON.parse(m.body);
        if (message.exception === "AccessDenied") {
            logout().then(() => {
                console.warn("logout: AccessDenied: " + message.message);
                notify("ra.notification.logged_out", {type: "warning"});
            });
        } else {
            console.warn("exception: " + message.exception + "message: " + message.message);
            notify("ra.notification.http_error", {type: "warning"});
        }
    };

    const onVehicleStatusMessage = (m: StompJS.Message) => {
        const message = JSON.parse(m.body);
        if (isVehicleStatusHolder(message)) {
            setVehicleStatuses(message.vehicleStatuses);
        }
    };

    const onVSHelicoptersMessage = (m: StompJS.Message) => {
        const message = JSON.parse(m.body);
        if (isVSHelicoptersHolder(message)) {
            setHelicopters(message.helicopters);
        }
    };

    const onVSRolesMessage = (m: StompJS.Message) => {
        const message = JSON.parse(m.body);
        if (isVSRolesHolder(message)) {
            setRoles(message.roles);
        }
    };

    const onVSUsersMessage = (m: StompJS.Message) => {
        const message = JSON.parse(m.body);
        if (isVSUsersHolder(message)) {
            setUsers(message.users);
        }
    };

    const onConnect = () => {
        setConnected(true);
    };

    const onDisconnect = () => {
        setConnected(false);
    };

    useStompWS({
        url: WEBSOCKET_URL,
        topics: [
            {topic: "/error", onMessage: onErrorMessage},
            {topic: "/vehicles", onMessage: onVehicleStatusMessage},
            {topic: "/helicopters", onMessage: onVSHelicoptersMessage},
            {topic: "/roles", onMessage: onVSRolesMessage},
            {topic: "/users", onMessage: onVSUsersMessage}
        ],
        heartbeatIncoming: 5000,
        heartbeatOutgoing: 5000,
        onConnect,
        onDisconnect,
        onWebSocketError: onDisconnect
    });

    const ConnectionLostCard = () => (
        <Card square elevation={3}
              sx={{
                  margin: "0.375rem",
                  minHeight: "4rem",
                  backgroundColor: daRedRegular,
                  display: "grid"
              }}
        >
            <Box
                display="grid"
                alignItems="center"
                justifyItems="start"
                pt={3} pr={8} pl={12} pb={5}
            >
                <Typography variant="h2" color="secondary" sx={{color: daWhite}}>
                    {translate("da.connection.lost.long")}
                </Typography>
            </Box>
        </Card>
    );

    return (
        <>
            {showDisconnectBanner && <ConnectionLostCard/>}
            <VehicleStatuses
                vehicleStatuses={vehicleStatuses}
                helicopters={helicopters}
                roles={roles}
                users={users}
            />
        </>
    );
};


export type VehicleStatusHolder = {
    vehicleStatuses: VehicleStatusDTO[]
}

export type VSHelicoptersHolder = {
    helicopters: { [helicopterId in number]: VSHelicopter }
}

export type VSRolesHolder = {
    roles: { [roleId in number]: VSRole }
}

export type VSUsersHolder = {
    users: { [userId in number]: VSUser }
}
const emptyVehicleStatusHolder: VehicleStatusHolder = {vehicleStatuses: []};
const emptyHelicoptersHolder: VSHelicoptersHolder = {helicopters: {}};
const emptyRolesHolder: VSRolesHolder = {roles: {}};
const emptyUsersHolder: VSUsersHolder = {users: {}};

// type = VehicleStatus extends StompJS.IMessage {
//     vehicles:
// }

export const isVehicleStatusHolder = (obj: object): obj is VehicleStatusHolder => "vehicleStatuses" in (obj as VehicleStatusHolder);

export const isVSHelicoptersHolder = (obj: object): obj is VSHelicoptersHolder => "helicopters" in (obj as VSHelicoptersHolder);

export const isVSRolesHolder = (obj: object): obj is VSRolesHolder => "roles" in (obj as VSRolesHolder);

export const isVSUsersHolder = (obj: object): obj is VSUsersHolder => "users" in (obj as VSUsersHolder);
export default Dashboard;
