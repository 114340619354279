import * as React from "react";
import { ReactComponent as NotificationNotDeliveredSvgIcon } from "../images/ic-notification-not-delivered.svg";
import { ReactComponent as NotificationReadIcon } from "../images/ic-notification-read.svg";
import { ReactComponent as NotificationReceivedIcon } from "../images/ic-notification-received.svg";
import { daGreen, daOrange, daRedRegular } from "../layout/Theme";
import { ConfirmationType } from "./VehicleStatuses";

const ConfirmationTypeIcon: React.FC<{ confirmationType: ConfirmationType }> = ({confirmationType}) => {
    if (confirmationType === "NO_NOTIFICATION") {
        return null;
    }

    const Element = type2Element[confirmationType];
    return <Element color={type2Color[confirmationType]}/>;
};

const type2Element: { [K in Exclude<ConfirmationType, "NO_NOTIFICATION">]: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }> } = {
    RECEIVED_PUSH: NotificationReceivedIcon,
    READ_IN_APP: NotificationReadIcon,
    NOT_DELIVERED: NotificationNotDeliveredSvgIcon
};

const type2Color: { [K in Exclude<ConfirmationType, "NO_NOTIFICATION">]: string } = {
    RECEIVED_PUSH: daOrange,
    READ_IN_APP: daGreen,
    NOT_DELIVERED: daRedRegular
};

export default ConfirmationTypeIcon;

