import { chunk, flatMap, map } from "lodash-es";
import { stringify } from "query-string";
import {
    DataProvider,
    fetchUtils,
    GetListParams,
    GetListResult,
    GetManyParams,
    GetManyResult,
    RaRecord,
    SortPayload
} from "react-admin";

export const API_BASE_URL = `${process.env.REACT_APP_API_URL}/v1`;
export const WEB_BASE_URL = `${API_BASE_URL}/web`;

export const httpClient = (url: string, options: any = {}) => {
    options.headers = options.header ?? new Headers({Accept: "application/json"});
    options.credentials = "include";
    return fetchUtils.fetchJson(url, options);
};

const dataProvider: DataProvider = {
    getList: (resource, {pagination, sort, filter}) => {
        const {page, perPage} = pagination;
        const {field, order} = sort;
        const query = {page: page - 1, perPage, field, order, ...filter};

        return httpClient(`${WEB_BASE_URL}/${resource}?${stringify(query)}`).then(({headers, json}) => ({
            data: json,
            total: parseInt(headers.get("Content-Range")?.split("/").pop() ?? "0", 10)
        }));
    },

    getOne: (resource, {id}) =>
        httpClient(`${WEB_BASE_URL}/${resource}/${id}`).then(({json}) => ({data: json})),

    getMany: (resource, {ids}) =>
        httpClient(`${WEB_BASE_URL}/${resource}?ids=${ids.join(",")}`).then(({json}) => ({data: json})),

    getManyReference: (resource, {target, id, pagination, sort, filter}) => {
        const {page, perPage} = pagination;
        const {field, order} = sort;
        const query = {page: page - 1, perPage, field, order, [target]: id, ...filter};

        return httpClient(`${WEB_BASE_URL}/${resource}?${stringify(query)}`).then(({headers, json}) => ({
                data: json,
                total: parseInt(headers.get("Content-Range")?.split("/").pop() ?? "0", 10)
            }
        ));
    },

    update: (resource, {id, data}) =>
        httpClient(`${WEB_BASE_URL}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(data)
        }).then(({json}) => ({data: json})),

    updateMany: (resource, {ids, data}) =>
        httpClient(`${WEB_BASE_URL}/${resource}?ids=${ids.join(",")}`, {
            method: "PUT",
            body: JSON.stringify(data)
        }).then(({json}) => ({data: json})),

    create: (resource, {data}) =>
        httpClient(`${WEB_BASE_URL}/${resource}`, {
            method: "POST",
            body: JSON.stringify(data)
        }).then(({json}) => ({data: {...data, ...json}})),

    delete: (resource, {id}) =>
        httpClient(`${WEB_BASE_URL}/${resource}/${id}`, {
            method: "DELETE"
        }).then(({json}) => ({data: json})),

    deleteMany: (resource, {ids}) =>
        httpClient(`${WEB_BASE_URL}/${resource}?ids=${ids.join(",")}`, {
            method: "DELETE"
        }).then(({json}) => ({data: json}))
};

export const getListPaged: (resource: string, params: GetListPagedParams) => Promise<GetListResult> =
    (resource, {maxPages, ...params}) => {
        if (maxPages === undefined || maxPages < 0) {
            return getListPagedUntilEnd(resource, params);
        }
        return getListPagedUntilMaxPages(resource, {maxPages, ...params});
    };

export const getListPagedUntilMaxPages: (resource: string, params: GetListPagedUntilMaxPagesParams) => Promise<GetListResult> =
    (resource, {maxPages, perPage, sort, filter}) =>
        getPagesUntilMaxPages(resource, {pagination: {page: 1, perPage}, sort, filter}, maxPages, []);

const getPagesUntilMaxPages: (resource: string, params: GetListParams, maxPages: number, allResults: RaRecord[]) => Promise<GetListResult> =
    (resource, params, maxPages, allResults) =>
        dataProvider.getList(resource, params).then(result => {
            allResults = allResults.concat(result.data);

            if (params.pagination.page === maxPages || allResults.length === result.total) {
                return {data: allResults, total: result.total};
            }

            params.pagination.page++;
            return getPagesUntilMaxPages(resource, params, maxPages, allResults);
        });

export const getListPagedUntilEnd: (resource: string, params: GetListPagedUntilEndParams) => Promise<GetListResult> =
    (resource, {perPage, sort, filter}) =>
        getPagesUntilEnd(resource, {pagination: {page: 1, perPage}, sort, filter}, []);

const getPagesUntilEnd: (resource: string, params: GetListParams, allResults: RaRecord[]) => Promise<GetListResult> =
    (resource, params, allResults) =>
        dataProvider.getList(resource, params).then(result => {
            allResults = allResults.concat(result.data);

            if (allResults.length === result.total) {
                return {data: allResults, total: result.total};
            }

            params.pagination.page++;
            return getPagesUntilEnd(resource, params, allResults);
        });

export const getManyPaged: (resource: string, params: GetManyPagedParams) => Promise<GetManyResult> =
    (resource, {ids, perPage}) => {
        const resultPromises = map(chunk(ids, perPage), idChunk => dataProvider.getMany(resource, {ids: idChunk}));
        return Promise.all(resultPromises).then(results =>
            ({
                data: flatMap(results, result => result.data)
            })
        );
    };

export type GetListPagedParams = {
    maxPages?: number;
    perPage: number;
    sort: SortPayload;
    filter: any;
};

export type GetListPagedUntilMaxPagesParams = {
    maxPages: number;
    perPage: number;
    sort: SortPayload;
    filter: any;
};

export type GetListPagedUntilEndParams = {
    perPage: number;
    sort: SortPayload;
    filter: any;
};

export type GetManyPagedParams = GetManyParams & {
    perPage: number
};

export default dataProvider;
