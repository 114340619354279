import { filter, find, map, mapValues, values } from "lodash-es";
import * as React from "react";
import { VehicleStatusCard } from "./VehicleStatusCard";

export const VehicleStatuses: React.FC<VehicleStatusesProps> = ({
                                                                    vehicleStatuses,
                                                                    helicopters,
                                                                    roles,
                                                                    users
                                                                }) => {
    const vehicleStatusCards = vehicleStatuses.map((vehicleStatusDTO) => {
        const crewByRoleId = {
            roleId1: filter(
                vehicleStatusDTO.crew,
                (cmDTO) => cmDTO.roleId === 1
            ),
            roleId2: filter(
                vehicleStatusDTO.crew,
                (cmDTO) => cmDTO.roleId === 2
            ),
            roleId3: filter(
                vehicleStatusDTO.crew,
                (cmDTO) => cmDTO.roleId === 3
            ),
            roleIdOther: filter(
                vehicleStatusDTO.crew,
                (cmDTO) => cmDTO.roleId > 3
            )
        };
        const crew = mapValues(crewByRoleId, (cmDTOs) =>
            map(cmDTOs, (cmDTO) => ({
                ...cmDTO,
                ...roles[cmDTO.roleId],
                ...users[cmDTO.userId]
            }))
        );

        const rescueVehicleId = vehicleStatusDTO.rescueVehicle.vehicleId;

        const helicopter = find(values(helicopters), heli => heli.rescueVehicleId === rescueVehicleId);

        return (
            <VehicleStatusCard
                key={rescueVehicleId}
                vehicleStatus={{...vehicleStatusDTO, crew}}
                helicopter={helicopter}
                roles={roles}
            />
        );
    });

    return <>{vehicleStatusCards}</>;
};

type VehicleStatusesProps = {
    vehicleStatuses: VehicleStatusDTO[];
    helicopters: { [helicopterId in number]: VSHelicopter };
    roles: { [roleId in number]: VSRole };
    users: { [userId in number]: VSUser };
};

export type VSHelicopter = {
    helicopterId: number;
    registration: string;
    phoneNumber: string;
    rescueVehicleId: number;
};

export type VSRole = {
    roleId: number;
    roleTitle: string;
    rolePriority: number;
};

export type VSUser = {
    userId: number;
    firstName: string;
    lastName: string;
};

export type VehicleStatus = {
    rescueVehicle: VehicleDTO;
    status: VSMissionStatus;
    crew: CrewMemberByRole;
};

export type CrewMemberByRole = {
    roleId1: VSCrewMember[];
    roleId2: VSCrewMember[];
    roleId3: VSCrewMember[];
    roleIdOther: VSCrewMember[];
};

export type VehicleStatusDTO = {
    rescueVehicle: VehicleDTO;
    status: VSMissionStatus;
    crew: VSCrewMemberDTO[];
};

export type VehicleDTO = {
    vehicleId: number;
    name: string;
};

export type VSMissionStatus =
    | "MISSION_PENDING"
    | "MISSION_ACKNOWLEDGED"
    | "IDLE";

export type VSCrewMember = {
    userId: number;
    firstName: string;
    lastName: string;
    roleId: number;
    roleTitle: string;
    rolePriority: number;
    shiftId: number;
    shiftStartedAt: number;
    confirmationType: ConfirmationType;
    phoneNumber: string;
    crewLimit: string;
};

export type VSCrewMemberDTO = {
    userId: number;
    roleId: number;
    shiftId: number;
    shiftStartedAt: number;
    confirmationType: ConfirmationType;
    phoneNumber: string;
    crewLimit: string;
};

export type ConfirmationType =
    | "RECEIVED_PUSH"
    | "READ_IN_APP"
    | "NOT_DELIVERED"
    | "NO_NOTIFICATION";
