import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { Button, useResourceContext } from "react-admin";
import { Link } from "react-router-dom";

const CreateButtonNoFab = () => {
    const resource = useResourceContext();
    return (
        <Button
            component={Link}
            to={`/${resource}/create`}
            label="ra.action.create"
        >
            <AddIcon/>
        </Button>
    );
};

export default CreateButtonNoFab;
