import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import { useSidebarState } from "react-admin";

export const SidebarToggleButton = () => {
    const [open, setOpen] = useSidebarState();
    const theme = useTheme();

    const toggleSidebar = (isOpen: boolean) => setOpen(!isOpen);

    const menuButtonClass = open ? {
        transition: theme.transitions.create(["transform"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        transform: "rotate(180deg)"
    } : {
        transition: theme.transitions.create(["transform"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        transform: "rotate(0deg)"
    };

    return (
        <IconButton
            color="inherit"
            onClick={() => toggleSidebar(open)}
            size="large"
            sx={{
                padding: 8,
                minWidth: "3rem",
                marginRight: "0.5rem"
            }}
        >
            <MenuIcon
                sx={{
                    ...menuButtonClass,
                    fontSize: 32
                }}
            />
        </IconButton>
    );
};
