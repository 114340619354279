import * as React from "react";
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    Labeled,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext
} from "react-admin";

import { SaveAndDeleteWithConfirmToolbar } from "../customComponents/CustomToolbars";
import {
    CreateViewActions,
    EditViewActions,
    ListViewActions,
    ShowViewActions
} from "../customComponents/CustomTopToolbars";
import { dateTimeOptionsDeCH, ORDER, Props2String, viewRedirect } from "./Common";
import { HelicopterProps } from "./Helicopters";
import { RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";
import { RoleProps, RolesResource } from "./Roles";

export const UsersResource = "users";

const UserListFilters = () => (
    <Filter>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const UserList = () => (
    <List
        filters={<UserListFilters/>}
        perPage={25}
        exporter={false}
        empty={false}
        actions={<ListViewActions/>}
    >
        <Datagrid
            bulkActionButtons={false}
            rowClick={viewRedirect.edit}
            rowStyle={() => ({height: "2.25rem"})}
        >
            <TextField source={UserProps.firstName}/>
            <TextField source={UserProps.lastName}/>
            <ReferenceField
                source={UserProps.defaultRoleId}
                reference={RolesResource}
                link={viewRedirect.show}
            >
                <TextField source={RoleProps.title}/>
            </ReferenceField>
            <ReferenceField
                source={UserProps.defaultVehicleId}
                reference={RescueVehiclesResource}
                link={viewRedirect.show}
            >
                <TextField source={RescueVehicleProps.name}/>
            </ReferenceField>
            <EditButton
                label=""
                sx={{
                    padding: "0px",
                    verticalAlign: "inherit",
                    minWidth: 0
                }}
            />
        </Datagrid>
    </List>
);

const ConditionalCrewLimitField = () => {
    const record = useRecordContext();
    return record &&
           (record.defaultRoleId === 1 || record.defaultRoleId === 2) ? (
               <Labeled label="Crew Limit">
                   <TextField source={UserProps.crewLimit}/>
               </Labeled>
           ) : null;
};

export const UserShow = () => (
    <Show actions={<ShowViewActions/>}>
        <SimpleShowLayout>
            <TextField source={UserProps.firstName}/>
            <TextField source={UserProps.lastName}/>
            <ReferenceField
                source={UserProps.defaultRoleId}
                reference={RolesResource}
                link={viewRedirect.show}
            >
                <TextField source={RoleProps.title}/>
            </ReferenceField>
            <ReferenceField
                source={UserProps.defaultVehicleId}
                reference={RescueVehiclesResource}
                link={viewRedirect.show}
            >
                <TextField source={RescueVehicleProps.name}/>
            </ReferenceField>
            <ConditionalCrewLimitField/>
            <DateField source={HelicopterProps.createdAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
            <DateField source={HelicopterProps.lastModified} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
        </SimpleShowLayout>
    </Show>
);

const ConditionalCrewLimitInput = () => {
    const record = useRecordContext();
    return (
        <TextInput
            source={UserProps.crewLimit}
            disabled={
                record == null ||
                !(record.defaultRoleId === 1 || record.defaultRoleId === 2)
            }
        />
    );
};

export const UserEdit = () => (
    <Edit actions={<EditViewActions/>} redirect={viewRedirect.list}>
        <SimpleForm
            warnWhenUnsavedChanges
            toolbar={
                <SaveAndDeleteWithConfirmToolbar
                    confirmContent="da.users.delete.confirm.text"
                    translateOptionsFunc={(record) => ({name: user2NameString(record)})}/>
            }
        >
            <TextInput source={UserProps.firstName}/>
            <TextInput source={UserProps.lastName}/>
            <ReferenceInput
                source={UserProps.defaultRoleId}
                reference={RolesResource}
                sort={{field: RoleProps.id, order: ORDER.ASC}}
            >
                <SelectInput optionText={RoleProps.title}/>
            </ReferenceInput>
            <ReferenceInput
                source={UserProps.defaultVehicleId}
                reference={RescueVehiclesResource}
                sort={{field: RescueVehicleProps.id, order: ORDER.ASC}}
            >
                <SelectInput optionText={RescueVehicleProps.name}/>
            </ReferenceInput>
            <ConditionalCrewLimitInput/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = () => (
    <Create actions={<CreateViewActions/>} redirect={viewRedirect.list}>
        <SimpleForm warnWhenUnsavedChanges>
            <TextInput source={UserProps.firstName} validate={required()}/>
            <TextInput source={UserProps.lastName} validate={required()}/>
            <ReferenceInput
                source={UserProps.defaultRoleId}
                reference={RolesResource}
                sort={{field: RoleProps.id, order: ORDER.ASC}}
                validate={required()}
            >
                <SelectInput optionText={RoleProps.title}/>
            </ReferenceInput>
            <ReferenceInput
                source={UserProps.defaultVehicleId}
                reference={RescueVehiclesResource}
                sort={{field: RescueVehicleProps.id, order: ORDER.ASC}}
                validate={required()}
            >
                <SelectInput optionText={RescueVehicleProps.name}/>
            </ReferenceInput>
            <ConditionalCrewLimitInput/>
        </SimpleForm>
    </Create>
);

export const user2NameString = (user?: RaRecord) =>
    isUser(user) ? `${user.firstName ? user.firstName + " " : ""}${user.lastName}` : "";

const isUser = (record?: RaRecord): record is User => {
    return record != null && UserProps.lastName in record;
};

export type User = RaRecord & {
    id: number;
    firstName: string;
    lastName: string;
    defaultRoleId: number;
    defaultVehicleId: number;
    createdAt: number;
    lastModified: number;
    crewLimit: string;
};

export const UserProps: Props2String<User> = {
    id: "id",
    firstName: "firstName",
    lastName: "lastName",
    defaultRoleId: "defaultRoleId",
    defaultVehicleId: "defaultVehicleId",
    createdAt: "createdAt",
    lastModified: "lastModified",
    crewLimit: "crewLimit"
};
