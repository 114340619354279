import * as React from "react";
import { DeleteWithConfirmButton, RaRecord, SaveButton, Toolbar, useRecordContext } from "react-admin";
import { useFormState } from "react-hook-form";

export const SaveToolbar = () => {
    const {isDirty} = useFormState();
    return (
        <Toolbar
            sx={{
                flex: "1",
                display: "flex",
                padding: "16px",
                justifyContent: "space-between",
                minHeight: "36px"
            }}
        >
            <SaveButton disabled={!isDirty}/>
        </Toolbar>
    );
};

export const SaveAndDeleteWithConfirmToolbar: React.FC<{
    confirmContent: string, translateOptionsFunc: (record: RaRecord) => {
        id?: string,
        name?: string
    }
}> =
    ({confirmContent, translateOptionsFunc}) => {
        const record = useRecordContext();
        const {isDirty} = useFormState();
        return (
            <Toolbar
                sx={{
                    flex: "1",
                    display: "flex",
                    padding: "16px",
                    justifyContent: "space-between",
                    minHeight: "36px"
                }}
            >
                <SaveButton disabled={!isDirty}/>
                <DeleteWithConfirmButton
                    record={record}
                    confirmContent={confirmContent}
                    translateOptions={translateOptionsFunc(record)}
                />
            </Toolbar>
        );
    };

