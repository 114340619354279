import ContentCreate from "@mui/icons-material/Create";
import ImageEye from "@mui/icons-material/RemoveRedEye";
import SettingsBackupRestore from "@mui/icons-material/SettingsBackupRestore";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {
    Button,
    Edit,
    Labeled,
    RaRecord,
    required,
    SaveButton,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useDataProvider,
    useRecordContext,
    useTranslate,
    useUpdate
} from "react-admin";
import { useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Props2String } from "../resources/Common";

export const SettingsResource = "settings";

export const SettingsEdit = () => {
    const translate = useTranslate();
    return (
        <Edit id={1} resource={SettingsResource} actions={<SettingsEditActions/>}>
            <SimpleForm toolbar={<SaveAndResetToolbar/>}>
                <Typography variant="h3">{translate("da.routes.settings.titles.nightMode")}</Typography>
                <TextInput source={SettingsProps.nightFrom} validate={required()}
                           label={`da.routes.settings.fields.${SettingsProps.nightFrom}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
                <Labeled label={`da.routes.settings.fields.${SettingsProps.defaultNightFrom}`}>
                    <TextField source={SettingsProps.defaultNightFrom} color="text.secondary"
                               sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
                </Labeled>

                <Divider sx={{marginTop: "1rem", marginBottom: "1rem", flexBasis: "100%"}}/>

                <TextInput source={SettingsProps.nightTo} validate={required()}
                           label={`da.routes.settings.fields.${SettingsProps.nightTo}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
                <Labeled label={`da.routes.settings.fields.${SettingsProps.defaultNightTo}`}>
                    <TextField source={SettingsProps.defaultNightTo} color="text.secondary"
                               sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
                </Labeled>
            </SimpleForm>
        </Edit>
    );
};

const SettingsEditActions = () => {
    const navigate = useNavigate();
    return (
        <TopToolbar>
            <Button
                label="ra.action.show"
                color="primary"
                onClick={() => navigate("./show")}
            >
                <ContentCreate/>
            </Button>
        </TopToolbar>
    );
};

const SettingsResetButton = () => {
    const record = useRecordContext<Settings>();
    useDataProvider();
    const [update, {isLoading}] = useUpdate<Settings>();

    const onClick = () => {
        const newRecord = {...record, nightFrom: record.defaultNightFrom, nightTo: record.defaultNightTo};
        // noinspection JSIgnoredPromiseFromCall
        update(SettingsResource, {id: 1, data: newRecord, previousData: record});
    };
    return (
        <Button
            label="ra.action.resetToDefault"
            onClick={onClick}
            disabled={isLoading}
            size="medium"
            color={"warning" as any}
        >
            <SettingsBackupRestore/>
        </Button>
    );
};

export const SaveAndResetToolbar = () => {
    const {isDirty} = useFormState();
    return (
        <Toolbar
            sx={{
                flex: "1",
                display: "flex",
                padding: "16px",
                justifyContent: "space-between",
                minHeight: "36px"
            }}
        >
            <SaveButton disabled={!isDirty}/>
            <SettingsResetButton/>
        </Toolbar>
    );
};

export const SettingsShow = () => {
    const translate = useTranslate();
    return (
        <Show id={1} resource={SettingsResource} actions={<SettingsShowActions/>}>
            <SimpleShowLayout>
                <Typography variant="h3">{translate("da.routes.settings.titles.nightMode")}</Typography>
                <Box sx={{flexBasis: "100%"}}/>
                <TextField source={SettingsProps.nightFrom}
                           label={`da.routes.settings.fields.${SettingsProps.nightFrom}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}
                />
                <TextField source={SettingsProps.defaultNightFrom}
                           label={`da.routes.settings.fields.${SettingsProps.defaultNightFrom}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>

                <Divider sx={{marginTop: "1rem", marginBottom: "1rem", flexBasis: "100%"}}/>

                <TextField source={SettingsProps.nightTo}
                           label={`da.routes.settings.fields.${SettingsProps.nightTo}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
                <TextField source={SettingsProps.defaultNightTo}
                           label={`da.routes.settings.fields.${SettingsProps.defaultNightTo}`}
                           sx={{display: "inline-flex", marginRight: "1rem", verticalAlign: "top"}}/>
            </SimpleShowLayout>
        </Show>
    );
};

const SettingsShowActions = () => {
    const navigate = useNavigate();
    return (
        <TopToolbar>
            <Button
                label="ra.action.edit"
                color="primary"
                onClick={() => navigate("./..")}
            >
                <ImageEye/>
            </Button>
        </TopToolbar>
    );
};

export type Settings =
    RaRecord
    & { nightFrom: string, nightTo: string; defaultNightFrom: string, defaultNightTo: string };

export const SettingsProps: Props2String<Settings> = {
    id: "id",
    nightFrom: "nightFrom",
    nightTo: "nightTo",
    defaultNightFrom: "defaultNightFrom",
    defaultNightTo: "defaultNightTo"
};
