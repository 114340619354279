import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { head, mapValues, some, values } from "lodash-es";
import * as React from "react";
import { usePermissions } from "react-admin";
import { PHONE_AUTHORITY } from "../authProvider/authProvider";
import { daBlack, daBlueLight, daBlueMedium } from "../layout/Theme";
import CrewMemberCard from "./CrewMemberCard";
import MissionsStatusTitle from "./MissionsStatusTitle";
import PhoneConferenceButton, { extractCrewPhoneNumbers } from "./PhoneConferenceButton";
import VehicleStatusDialog from "./VehicleStatusDialog";
import { VehicleStatus, VSHelicopter, VSMissionStatus, VSRole } from "./VehicleStatuses";

export const VehicleStatusCard: React.FC<VehicleStatusCardProps> = ({vehicleStatus, helicopter, roles}) => {
    const elevation = vehicleStatus2Elevation[vehicleStatus.status];
    const textBgColor = vehicleStatus2BgColor[vehicleStatus.status];

    const prominentCrewMembers = mapValues(
        mapValues(
            vehicleStatus.crew,
            role2Cms => ({cm: head(role2Cms), perRole: role2Cms.length})
        ),
        ({cm, perRole}) =>
            <>
                {cm
                    ? <CrewMemberCard key={cm.shiftId} crewMember={cm} perRole={perRole}/>
                    : <EmptyCrewMemberCard/>
                }
                <CreMemberDivider/>
            </>
    );
    const crewPhoneNumbers = extractCrewPhoneNumbers(vehicleStatus.crew);

    const showAllButton = some(values(vehicleStatus.crew), cms => cms.length > 1);

    const {permissions} = usePermissions();
    const hasPhonePermission = permissions?.includes(PHONE_AUTHORITY);

    return (
        <Card square
              elevation={elevation}
              key={vehicleStatus.rescueVehicle.vehicleId}
              sx={{
                  margin: "0.375rem",
                  minHeight: "4rem",
                  "&:first-of-type": {
                      marginTop: "0.75rem"
                  },
                  "&:last-child": {
                      marginBottom: "0.75rem"
                  },
                  display: "grid",
                  gridTemplateColumns: "minmax(10rem, auto) 4fr 0.25fr 4px",
                  gridColumnGap: "4px"
              }}
              style={{borderRight: `4px solid ${textBgColor}`}}
        >
            <MissionsStatusTitle
                vehicleName={vehicleStatus.rescueVehicle.name}
                vehicleStatus={vehicleStatus.status}
                helicopter={helicopter}
            />
            <Box
                sx={{
                    margin: "0.25rem",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr 2px) 0.25fr",
                    gridColumnGap: "4px",
                    border: "2px solid transparent",
                    borderRadius: "5px",
                    "&:has(#phoneConferenceButton:hover)":{
                        border: `2px solid ${daBlueLight}`
                    }
                }}
            >
                {prominentCrewMembers.roleId1}
                {prominentCrewMembers.roleId2}
                {prominentCrewMembers.roleId3}
                {prominentCrewMembers.roleIdOther}
                {hasPhonePermission && crewPhoneNumbers.length > 0 &&
                    <Box
                        id="phoneConferenceButton"
                        sx={{
                            display: "grid",
                            placeItems: "center",
                            "&:hover path": {
                                fill: daBlack
                            }
                        }}
                    >
                        <PhoneConferenceButton phoneNumbers={crewPhoneNumbers}/>
                    </Box>
                }
            </Box>
            <Box
                sx={{
                    display: "grid",
                    placeItems: "center",
                }}
            >
                {showAllButton &&
                    <VehicleStatusDialog vehicleStatus={vehicleStatus} helicopter={helicopter} roles={roles}/>
                }
            </Box>
            <Box bgcolor={textBgColor}/>
        </Card>
    );
};

const EmptyCrewMemberCard = () =>
    <Box display="grid" justifyItems="center" alignItems="center" color={daBlueMedium}>-</Box>;

const CreMemberDivider = () => <Box
    sx={{
        margin: "5px 0px",
        backgroundColor: daBlueLight
    }}
/>;

type VehicleStatusCardProps = {
    vehicleStatus: VehicleStatus,
    helicopter?: VSHelicopter
    roles: { [roleId in number]: VSRole }
};

export const vehicleStatus2BgColor: { [K in VSMissionStatus]: string } = {
    IDLE: "background.paper",
    MISSION_PENDING: "secondary.main",
    MISSION_ACKNOWLEDGED: "primary.main"
};

export type MissionsStatusTitleProps = {
    vehicleName: string,
    vehicleStatus: VSMissionStatus,
    helicopter?: VSHelicopter
};

const vehicleStatus2Elevation: { [K in VSMissionStatus]: number } = {
    IDLE: 0,
    MISSION_PENDING: 3,
    MISSION_ACKNOWLEDGED: 3
};
