import { stringify } from "query-string";
import { API_BASE_URL, httpClient } from "./dataProvider";

export const makeCall = (phoneNumber: string, clientId: string) => {
    return httpClient(
        `${API_BASE_URL}/mico/makecall?${stringify({phoneNumber, clientId})}`,
        {method: "POST"}
    ).then(({json}: any) => ({data: json}));
};

export const makeConference = (phoneNumbers: string[], clientId: string) => {
    return httpClient(
        `${API_BASE_URL}/mico/makeconference?${stringify({phoneNumbers, clientId})}`,
        {method: "POST"}
    ).then(({json}: any) => ({data: json}));
};
