import Typography from "@mui/material/Typography";
import { flatMap, groupBy, keyBy, orderBy, values } from "lodash-es";
import * as React from "react";
import {
    AutocompleteInput,
    Datagrid,
    Exporter,
    GetListResult,
    List,
    Pagination,
    RaRecord,
    ReferenceInput,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    Translate,
    useTranslate
} from "react-admin";
import { csvExport } from "../customComponents/PagedExportButtonWithFilter";
import RecordLinkField from "../customComponents/RecordLinkField";
import { ORDER, Props2String, RequiredOnly, StatsWithExportListActions, toDeCHLocaleString } from "./Common";
import { RescueVehicle, RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";
import { ShiftStats, ShiftStatsByRescueVehicleResource, ShiftStatsDatagrid, ShiftStatsProps } from "./ShiftStats";
import { User, UsersResource } from "./Users";

export const ReadinessByRescueVehicleResource = "readiness/rescuevehicle";
export const ReadinessByRescueVehicle2ShiftStatsResource = `${ReadinessByRescueVehicleResource}/shifts`;

export const ReadinessByRescueVehicleList = () => {
    const translate = useTranslate();
    const filters = readinessByRescueVehicleFilters(translate);
    return (
        <List perPage={25} empty={false}
              filters={filters}
              exporter={readinessByRescueVehicleExporter}
              sort={{field: ReadinessByRescueVehicleProps.id, order: ORDER.ASC}}
              actions={<StatsWithExportListActions filters={filters}/>}
        >
            <Datagrid
                bulkActionButtons={false}
                rowStyle={() => ({height: "2.25rem"})}
                rowClick="show"
            >
                <RecordLinkField source={ReadinessByRescueVehicleProps.name} linkResource={RescueVehiclesResource}/>
            </Datagrid>
        </List>
    );
};

const readinessByRescueVehicleFilters: (translate: Translate) => JSX.Element[] = (translate) => {
    const field2LabelAndSource = (field: keyof ReadinessByRescueVehicleFilterFields) => ({
        label: translate(`da.stats.readiness.rescueVehicle.filter.${field}`),
        source: field,
        style: {minWidth: "10rem", marginLeft: "4px", marginRight: "4px"}
    });

    return [
        <ReferenceInput
            {...field2LabelAndSource(ReadinessByRescueVehicleFilterFieldsProps.id)}
            reference={RescueVehiclesResource}
            sort={{field: RescueVehicleProps.id, order: ORDER.ASC}}
            alwaysOn
        >
            <AutocompleteInput source={RescueVehicleProps.name}/>
        </ReferenceInput>
    ];
};

const readinessByRescueVehicleExporter: Exporter = (listResult: GetListResult<ReadinessByRescueVehicle>, fetchRelatedRecords) => {
    return Promise.all([
            listResult.data,
            fetchRelatedRecords(listResult.data, ReadinessByRescueVehicleProps.id, ReadinessByRescueVehicle2ShiftStatsResource) as PromiseLike<ShiftStats[]>,
            fetchRelatedRecords(listResult.data, ReadinessByRescueVehicleProps.id, RescueVehiclesResource) as PromiseLike<User[]>
        ])
        .then(([allReadinessByRescueVehicle, relatedShiftStats, relatedRescueVehicles]) => {
            return Promise.all([
                allReadinessByRescueVehicle,
                relatedShiftStats,
                relatedRescueVehicles,
                fetchRelatedRecords(values(relatedShiftStats), ShiftStatsProps.userId, UsersResource) as PromiseLike<RescueVehicle[]>
            ]);
        })
        .then(([allReadinessByRescueVehicle, relatedShiftStats, relatedRescueVehicles, relatedUsers]) => {
            const id2RescueVehicle = keyBy(relatedRescueVehicles, "id");
            const rescueVehicleId2Shifts = groupBy(relatedShiftStats, "rescueVehicleId");
            const userId2User = keyBy(relatedUsers, "id");

            const allReadinessByRescueVehicleForExport = orderBy(
                flatMap(allReadinessByRescueVehicle, readinessByRescueVehicle => {
                    // do not include id, name
                    const {
                        id: rescueVehicleId,
                        name,
                        ...readinessByRescueVehicleRest
                    } = readinessByRescueVehicle;

                    return flatMap(rescueVehicleId2Shifts[rescueVehicleId], shiftStats => {
                        // do not include id, roleId, rescueVehicle, startedAt, finishedAt
                        const {
                            id: shiftId,
                            startedAt,
                            finishedAt,
                            ...shiftStatsRest
                        } = shiftStats;

                        const user = userId2User[shiftStats.userId];
                        const rescueVehicle = id2RescueVehicle[rescueVehicleId];

                        return {
                            ...readinessByRescueVehicleRest,
                            ...shiftStatsRest,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            shiftId,
                            rescueVehicleName: rescueVehicle.name,
                            startedAt: toDeCHLocaleString(startedAt),
                            finishedAt: toDeCHLocaleString(finishedAt)
                        } as ReadinessByRescueVehicleForExport;
                    });
                }),
                [
                    ReadinessByRescueVehicleForExportProps.rescueVehicleId,
                    ReadinessByRescueVehicleForExportProps.shiftId
                ],
                ["asc", "desc"]
            );

            csvExport(
                allReadinessByRescueVehicleForExport,
                {headers: values(ReadinessByRescueVehicleForExportProps)},
                "statistiken_bereitschaft_einsatzmittel"
            );
        });
};

const ReadinessByRescueVehicleExpandShiftsLabel = () => {
    const translate = useTranslate();
    return (
        <Typography variant="h3" gutterBottom>{translate("da.stats.readiness.expand.label.shifts")}</Typography>
    );
};

export const ReadinessByRescueVehicleShow = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceManyField
                pagination={<Pagination/>}
                source={ReadinessByRescueVehicleProps.id}
                reference={ShiftStatsByRescueVehicleResource}
                target={ShiftStatsProps.id}
                sort={{field: ShiftStatsProps.startedAt, order: ORDER.DESC}}
                label={<ReadinessByRescueVehicleExpandShiftsLabel/>}
                /*sx={{
                    display: "flex",
                    "& > div": {
                        flex: "1 1 auto"
                    }
                }}*/
            >
                <ShiftStatsDatagrid withUser={true}/>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

type ReadinessByRescueVehicle = RaRecord & {
    id: number,
    name: string
}

const ReadinessByRescueVehicleProps: Props2String<ReadinessByRescueVehicle> = {
    id: "id",
    name: "name"
};

type ReadinessByRescueVehicleForExport =
    Omit<RequiredOnly<ReadinessByRescueVehicle>, "id" | "name"> &
    Omit<RequiredOnly<ShiftStats>, "id" | "userName" | "startedAt" | "finishedAt"> & {
    firstName: string,
    lastName: string,
    shiftId: number;
    rescueVehicleName: string;
    startedAt: string;
    finishedAt: string;
};

const ReadinessByRescueVehicleForExportProps: Props2String<ReadinessByRescueVehicleForExport> = {
    rescueVehicleId: "rescueVehicleId",
    rescueVehicleName: "rescueVehicleName",
    shiftId: "shiftId",
    userId: "userId",
    firstName: "firstName",
    lastName: "lastName",
    roleId: "roleId",
    roleTitle: "roleTitle",
    deviceId: "deviceId",
    deviceName: "deviceName",
    startedAt: "startedAt",
    finishedAt: "finishedAt"
};

type ReadinessByRescueVehicleFilterFields = {
    id: string;
};

const ReadinessByRescueVehicleFilterFieldsProps: Props2String<ReadinessByRescueVehicleFilterFields> = {
    id: "id"
};
