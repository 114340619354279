const regaDaMessagesGerman = {
    ra: {
        message: {
            unsaved_changes:
                "Einige deiner Änderungen wurden nicht gespeichert. Bist du sicher, dass du diese ignorieren willst?"
        },
        page: {
            loading: "Laden..."
        },
        action: {
            resetToDefault: "Auf Standards zurücksetzen"
        }
    },
    dashboard: {
        name: "Einsätze"
    },
    resources: {
        helicopters: {
            name: "Helikopter",
            fields: {
                registration: "Registration",
                phoneNumber: "Telefonnummer",
                rescueVehicleId: "Einsatzmittel",
                createdAt: "Erstellt am",
                lastModified: "Zuletzt geändert"
            }
        },
        roles: {
            name: "Rollen",
            fields: {
                title: "Rolle",
                priority: "Prio",
                isSecondary: "Nebenrolle",
                canAcknowledgeMission: "Quittieren",
                createdAt: "Erstellt am",
                lastModified: "Zuletzt geändert"
            }
        },
        users: {
            name: "Benutzer",
            fields: {
                firstName: "Vorname",
                lastName: "Nachname",
                defaultRoleId: "Default Rolle",
                defaultVehicleId: "Default Einsatzmittel",
                createdAt: "Erstellt am",
                lastModified: "Zuletzt geändert",
                crewLimit: "Crew Limit"
            }
        },
        rescuevehicles: {
            name: "Einsatzmittel",
            fields: {
                name: "Einsatzmittel",
                isActive: "Aktiv",
                createdAt: "Erstellt am",
                lastModified: "Zuletzt geändert"
            }
        },
        "stats/missions": {
            name: "Einsatzstatistiken",
            fields: {
                id: "Mission ID",
                rescueVehicleId: "Einsatzmittel",
                startedAt: "Gestartet am",
                finishedAt: "Beendet am",
                notificationType: "Notification Type",
                initialNotificationType: "Initial Notification Type",
                elsEventId: "ELS Event Id",
                elsDispatchId: "ELS Dispatch Id",
                isAcknowledged: "Quittiert",
                acknowledgedAt: "Quittiert am"
            }
        },
        "stats/missioninfos": {
            fields: {
                id: "MissionInfo ID",
                missionId: "Mission ID",
                title: "Titel",
                description: "Remark",
                dangers: "Gefahren",
                rescueLocation: "Einsatzort",
                pickupLocation: "Abholort",
                destination: "Zielort",
                isRescueLocationRough: "Grobkoordinaten",
                isWinchRescue: "Winde",
                isRsh: "RSH",
                isAcknowledged: "Quittiert",
                createdAt: "Timestamp"
            }
        },
        "stats/notifications": {
            name: "Pushstatistiken",
            fields: {
                id: "Push ID",
                rescueVehicleId: "Einsatzmittel",
                role: "Rolle",
                startedAt: "Gestartet am",
                finishedAt: "Beendet am",
                userName: "Benutzer",
                deviceName: "Device",
                elsEventId: "ELS Event Id",
                elsDispatchId: "ELS Dispatch Id",
                triggeredAt: "Ausgelöst am",
                serverReceivedAt: "Erhalten am",
                serverReceivedAtRTT: "Erhalten RTT [ms]",
                serverReadAt: "Gelesen am",
                serverReadAtRTT: "Gelesen RTT [ms]"
            }
        },
        "readiness/user": {
            name: "Bereitschaft nach Benutzer",
            fields: {
                name: "Benutzer"
            }
        },
        "readiness/rescuevehicle": {
            name: "Bereitschaft nach Einsatzmittel",
            fields: {
                name: "Einsatzmittel"
            }
        },
        "stats/shifts/user": {
            fields: {
                id: "Shift ID",
                rescueVehicleId: "Einsatzmittel",
                roleTitle: "Rolle",
                deviceName: "Device",
                startedAt: "Gestartet am",
                finishedAt: "Beendet am"
            }
        },
        "stats/shifts/rescuevehicle": {
            fields: {
                id: "Shift ID",
                userName: "Benutzer",
                roleTitle: "Rolle",
                deviceName: "Device",
                startedAt: "Gestartet am",
                finishedAt: "Beendet am"
            }
        }
    },
    da: {
        connection: {
            lost: {
                short: "Verbindung zum Server verloren",
                long: "Keine Verbindung zum Server. Versuche Verbindung wieder herzustellen..."
            }
        },
        boolean: {
            true: "Ja",
            false: "Nein"
        },
        errorInUse: {
            edit: {
                role: "Rolle kann nicht editiert werden (verlinkt mit Benutzer oder auf Einsatzmittel angemeldet).",
                user: "Benutzer kann nicht editiert werden, da er auf einem Einsatzmittel angemeldet ist.",
                rescueVehicle:
                    "Einsatzmittel kann nicht editiert werden (verlinkt mit angemeldetem Benutzer oder als Default Einsatzmittel).",
                helicopter:
                    "Helikopter kann nicht editiert werden (verlinkt mit Einsatzmittel)."
            },
            delete: {
                role: "Rolle kann nicht gelöscht werden (verlinkt mit Benutzer oder auf Einsatzmittel angemeldet).",
                user: "Benutzer kann nicht gelöscht werden, da er auf einem Einsatzmittel angemeldet ist.",
                rescueVehicle:
                    "Einsatzmittel kann nicht gelöscht werden (verlinkt mit angemeldetem Benutzer oder als Default Einsatzmittel).",
                helicopter:
                    "Helikopter kann nicht editiert werden (verlinkt mit Einsatzmittel)."
            }
        },
        users: {
            delete: {
                confirm: {
                    text: "Benutzer %{name} wirklich löschen?"
                }
            }
        },
        helicopters: {
            unassignRescueVehicleId: {
                button: "Zuweisung Einsatzmittel aufheben",
                notification: "Zuweisung aufgehoben"
            },
            delete: {
                confirm: {
                    text: "Helikopter %{name} wirklich löschen?"
                }
            }
        },
        roles: {
            delete: {
                confirm: {
                    text: "Rolle %{name} wirklich löschen?"
                }
            }
        },
        stats: {
            mission: {
                expand: {
                    label: "Einsatzupdates"
                },
                filter: {
                    query: "Suche",
                    startedAfter: "Gestartet nach",
                    finishedBefore: "Beendet vor",
                    notificationType: "Notification Type",
                    initialNotificationType: "Initial Notification Type"
                }
            },
            readiness: {
                user: {
                    filter: {
                        id: "Benutzer"
                    }
                },
                rescueVehicle: {
                    filter: {
                        id: "Einsatzmittel"
                    }
                },
                expand: {
                    label: {
                        shifts: "Bereitschaftszeiten"
                    }
                }
            },
            notification: {
                filter: {
                    query: "Suche",
                    triggeredAfter: "Ausgelöst nach",
                    triggeredBefore: "Ausgelöst vor"
                },
                viewBy: {
                    user: "Nach Benutzer",
                    vehicle: "Nach Einsatzmittel"
                }
            }
        },
        routes: {
            settings: {
                name: "Einstellungen",
                titles: {
                    nightMode: "Nachtmodus (für Sounds)"
                },
                fields: {
                    nightFrom: "Nacht von",
                    defaultNightFrom: "Standard: Nacht von",
                    nightTo: "Nacht bis",
                    defaultNightTo: "Standard: Nacht bis"
                }
            }
        },
        phone: {
            connection: {
                start: "Verbindungsaufbau...",
                failed: "Fehler bei Verbindungsaufbau"
            },
        },
        conf: {
            connection: {
                start: "Verbindungsaufbau für Konferenz...",
                failed: "Fehler bei Verbindungsaufbau für Konferenz"
            },
        },
        workstation: "Arbeitsplatz"
    }
};

export default regaDaMessagesGerman;
