import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { map, mapValues } from "lodash-es";
import * as React from "react";
import { useState } from "react";
import { usePermissions } from "react-admin";
import { PHONE_AUTHORITY } from "../authProvider/authProvider";
import { daBlack, daBlueMedium, daBlueRegular, daBlueSuperlight } from "../layout/Theme";
import CrewMemberCard from "./CrewMemberCard";
import MissionStatusTitle from "./MissionsStatusTitle";
import PhoneConferenceButton, { extractCrewPhoneNumbers } from "./PhoneConferenceButton";
import { VehicleStatus, VSHelicopter, VSRole } from "./VehicleStatuses";

const VehicleStatusDialog: React.FC<VehicleStatusDialogProps> = ({vehicleStatus, helicopter, roles}) => {
    const [open, setOpen] = useState(false);

    const {permissions} = usePermissions();
    const hasPhonePermission = permissions?.includes(PHONE_AUTHORITY);
    const crewPhoneNumbers = extractCrewPhoneNumbers(vehicleStatus.crew);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const CrewMemberDivider = () => <Box
        sx={{
            backgroundColor: daBlueSuperlight
        }}
    />;

    const crewMembersGrouped = mapValues(vehicleStatus.crew, cms =>
        map(
            cms,
            cm => (
                <React.Fragment key={cm.shiftId}>
                    <CrewMemberCard crewMember={cm}/> <CrewMemberDivider/>
                </React.Fragment>
            )
        ));

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}
                    sx={{
                        fontSize: "0.875rem",
                        fontWeight: 700,
                        fontStyle: "normal",
                        lineHeight: 1.25,
                        letterSpacing: "normal",
                        color: daBlueMedium,
                        borderRadius: "1000px",
                        transition: "unset",
                        textTransform: "none",
                        padding: "3px 15px",
                        border: `2px solid ${daBlueMedium}`,
                        "&:hover": {
                            border: `2px solid ${daBlueRegular}`,
                            backgroundColor: "inherit",
                            color: daBlueRegular
                        }
                    }}
            >
                Alle
            </Button>
            <Dialog
                fullWidth={false}
                maxWidth="xl"
                open={open}
                PaperProps={{
                    sx: {
                        borderRadius: 0,
                        minWidth: "360px",
                        display: "flex",
                        flexDirection: "row"
                    }
                }}
                onClose={handleClose}
            >
                <DialogContent
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "minmax(10rem, 1fr) repeat(4, 1fr) 0.75fr",
                        gridColumnGap: "0.5rem",
                        padding: "24px 24px 24px 0",
                        "&:first-of-type": {
                            padding: "24px 24px 24px 0"
                        }
                    }}
                >
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <MissionStatusTitle
                            vehicleName={vehicleStatus.rescueVehicle.name}
                            vehicleStatus={vehicleStatus.status}
                            helicopter={helicopter}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyItems="start"
                            pt={3} pr={8} pl={12} pb={5}
                        >
                            <Typography variant="h3">{roles[1].roleTitle}</Typography>
                        </Box>
                        {crewMembersGrouped.roleId1}
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyItems="start"
                            pt={3} pr={8} pl={12} pb={5}
                        >
                            <Typography variant="h3">{roles[2].roleTitle}</Typography>
                        </Box>
                        {crewMembersGrouped.roleId2}
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyItems="start"
                            pt={3} pr={8} pl={12} pb={5}
                        >
                            <Typography variant="h3">{roles[3].roleTitle}</Typography>
                        </Box>
                        {crewMembersGrouped.roleId3}
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyItems="start"
                            pt={3} pr={8} pl={12} pb={5}
                        >
                            <Typography variant="h3">Andere</Typography>
                        </Box>
                        {crewMembersGrouped.roleIdOther}
                    </Box>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateRows: "4rem repeat(auto-fill, minmax(4rem, auto) 2px)"
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyItems="start"
                            pt={3} pr={8} pl={12} pb={5}
                            sx={{
                                "&:hover h4": {
                                    color: daBlack
                                },
                                "&:hover path": {
                                    fill: daBlack
                                }
                            }}
                        >
                            {hasPhonePermission && crewPhoneNumbers.length > 0 &&
                             <PhoneConferenceButton phoneNumbers={crewPhoneNumbers}>
                                 <Box
                                     display="flex"
                                     flexDirection="row"
                                     alignItems="center"
                                     justifyItems="start"
                                 >
                                     <Typography variant="h4" color={daBlueMedium}>
                                         Conf Call
                                     </Typography>
                                 </Box>
                             </PhoneConferenceButton>
                            }
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        placeItems: "start center",
                        padding: "24px 24px 24px 0"
                    }}
                >
                    <Button onClick={handleClose} color="primary"
                            sx={{
                                "&:hover": {
                                    backgroundColor: "rgba(89, 138, 179, 0.2)"
                                }
                            }}
                    >
                        <CloseIcon/>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

type VehicleStatusDialogProps = {
    vehicleStatus: VehicleStatus,
    helicopter: VSHelicopter | undefined,
    roles: { [roleId in number]: VSRole }
};

export default VehicleStatusDialog;
