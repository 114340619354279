import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    ListButton,
    RaRecord,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useRecordContext
} from "react-admin";
import { SaveToolbar } from "../customComponents/CustomToolbars";
import { EditViewActions } from "../customComponents/CustomTopToolbars";
import { dateTimeOptionsDeCH, Props2String, viewRedirect } from "./Common";

export const RescueVehiclesResource = "rescuevehicles";

export const RescueVehicleList = () => (
    <List perPage={25} exporter={false}>
        <Datagrid bulkActionButtons={false}
                  rowClick={viewRedirect.edit}
                  rowStyle={() => ({height: "2.25rem"})}>
            <TextField source={RescueVehicleProps.name}/>
            <BooleanField source={RescueVehicleProps.isActive}/>
            <EditButton
                label=""
                sx={{
                    padding: "0px",
                    verticalAlign: "inherit",
                    minWidth: 0
                }}
            />
        </Datagrid>
    </List>
);

const RescueVehicleShowViewActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            {record?.isActive && <EditButton/>}
            <ListButton/>
        </TopToolbar>
    );
};

export const RescueVehicleShow = () => (
    <Show actions={<RescueVehicleShowViewActions/>}>
        <SimpleShowLayout>
            <TextField source={RescueVehicleProps.name}/>
            <BooleanField source={RescueVehicleProps.isActive}/>
            <DateField source={RescueVehicleProps.createdAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
            <DateField source={RescueVehicleProps.lastModified} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
        </SimpleShowLayout>
    </Show>
);

export const RescueVehicleEdit = () => (
    <Edit actions={<EditViewActions/>} redirect={viewRedirect.list}>
        <SimpleForm warnWhenUnsavedChanges toolbar={<SaveToolbar/>}>
            <TextField source={RescueVehicleProps.name}/>
            <BooleanInput source={RescueVehicleProps.isActive}/>
        </SimpleForm>
    </Edit>
);

export type RescueVehicle = RaRecord & {
    id: number,
    name: string,
    isActive: boolean,
    createdAt: number,
    lastModified: number
};

export const RescueVehicleProps: Props2String<RescueVehicle> = {
    id: "id", name: "name", isActive: "isActive", createdAt: "createdAt", lastModified: "lastModified"
};
