import * as React from "react";
import {
    AutocompleteInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    List,
    RaRecord,
    ReferenceField,
    ReferenceInput,
    required,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    usePermissions
} from "react-admin";
import { ENTITIES_AUTHORITY } from "../authProvider/authProvider";
import { SaveAndDeleteWithConfirmToolbar, SaveToolbar } from "../customComponents/CustomToolbars";
import {
    CreateViewActions,
    EditViewActions,
    ListViewActions,
    ShowViewActions
} from "../customComponents/CustomTopToolbars";
import { dateTimeOptionsDeCH, ORDER, Props2String, viewRedirect } from "./Common";
import { RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";

export const HelicoptersResource = "helicopters";

const HeliListFilters = () => (
    <Filter>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const HelicopterList = () => {
    const {permissions} = usePermissions();
    const hasEntitiesAuth = permissions?.includes(ENTITIES_AUTHORITY);
    return (
        <List

            filters={<HeliListFilters/>}
            perPage={25}
            exporter={false}
            empty={false}
            actions={hasEntitiesAuth && <ListViewActions/>}
        >
            <Datagrid
                bulkActionButtons={false}
                rowClick={viewRedirect.edit}
                rowStyle={() => ({height: "2.25rem"})}
            >
                <TextField source={HelicopterProps.registration}/>
                <TextField source={HelicopterProps.phoneNumber}/>
                <ReferenceField source={HelicopterProps.rescueVehicleId}
                                reference={RescueVehiclesResource}
                                link={hasEntitiesAuth && viewRedirect.show}
                >
                    <TextField source={RescueVehicleProps.name}/>
                </ReferenceField>
            </Datagrid>
        </List>
    );
};

export const HelicopterShow = () => {
    const {permissions} = usePermissions();
    const hasEntitiesAuth = permissions?.includes(ENTITIES_AUTHORITY);
    return (
        <Show actions={<ShowViewActions/>}>
            <SimpleShowLayout>
                <TextField source={HelicopterProps.registration}/>
                <TextField source={HelicopterProps.phoneNumber}/>
                <ReferenceField source={HelicopterProps.rescueVehicleId}
                                reference={RescueVehiclesResource}
                                link={hasEntitiesAuth && viewRedirect.edit}
                >
                    <TextField source={RescueVehicleProps.name}/>
                </ReferenceField>
                <DateField source={HelicopterProps.createdAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
                <DateField source={HelicopterProps.lastModified} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
            </SimpleShowLayout>
        </Show>
    );
};

export const HelicopterEdit = () => {
    const {permissions} = usePermissions();
    const hasEntitiesAuth = permissions?.includes(ENTITIES_AUTHORITY);
    return (
        <Edit actions={<EditViewActions/>} redirect={viewRedirect.list}>
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={hasEntitiesAuth
                         ? <SaveAndDeleteWithConfirmToolbar
                             confirmContent="da.helicopters.delete.confirm.text"
                             translateOptionsFunc={(record) => ({name: record.registration})}/>
                         : <SaveToolbar/>
                }
            >
                {hasEntitiesAuth
                 ? <TextInput source={HelicopterProps.registration}/>
                 : <TextField source={HelicopterProps.registration}/>
                }
                {hasEntitiesAuth
                 ? <TextInput source={HelicopterProps.phoneNumber}/>
                 : <TextField source={HelicopterProps.phoneNumber}/>
                }
                <ReferenceInput
                    source={HelicopterProps.rescueVehicleId}
                    reference={RescueVehiclesResource}
                    sort={{field: RescueVehicleProps.id, order: ORDER.ASC}}
                    filter={{"allUnassigned": true}}
                >
                    <AutocompleteInput optionText={RescueVehicleProps.name}/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const HelicopterCreate = () => (
    <Create actions={<CreateViewActions/>} redirect={viewRedirect.list}>
        <SimpleForm warnWhenUnsavedChanges>
            <TextInput source={HelicopterProps.registration} validate={required()}/>
            <TextInput source={HelicopterProps.phoneNumber} validate={required()}/>
            <ReferenceInput
                source={HelicopterProps.rescueVehicleId}
                reference={RescueVehiclesResource}
                sort={{field: RescueVehicleProps.id, order: ORDER.ASC}}
                filter={{"allUnassigned": true}}
            >
                <AutocompleteInput optionText={RescueVehicleProps.name}/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export type Helicopter = RaRecord & {
    id: number;
    registration: string;
    phoneNumber: string;
    rescueVehicleId: number;
    createdAt: number;
    lastModified: number;
};

export const HelicopterProps: Props2String<Helicopter> = {
    id: "id",
    registration: "registration",
    phoneNumber: "phoneNumber",
    rescueVehicleId: "rescueVehicleId",
    createdAt: "createdAt",
    lastModified: "lastModified"
};
