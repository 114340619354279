import {
    Box,
    Button,
    Card,
    CardActions,
    CircularProgress,
    MenuItem,
    Paper,
    StyledEngineProvider,
    TextField
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { Notification, useLogin, useNotify, useTranslate } from "react-admin";
import Rega1_Background from "../images/rega-login-background.jpg";
import { ReactComponent as RegaSvgIcon } from "../images/rega-logo.svg";
import { envIsIntOrProd, workstationClientIdPairs } from "../workstationSettings/workStationSettings";

export type LoginFormValues = {
    username?: string;
    password?: string;
    workstationId?: string
};

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const login = useLogin();

    const defaultWorkStationId = envIsIntOrProd ? undefined : workstationClientIdPairs[0]?.workstationId;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [workstationId, setWorkstationId] = useState(defaultWorkStationId);
    const [errors, setErrors] = useState({} as LoginFormValues);

    const handleSubmit = () => {
        setLoading(true);
        const auth = {username, password, workstationId};
        const validationErrors = validate(auth);
        setErrors(validationErrors);
        if (!!validationErrors.username || !!validationErrors.password || !!validationErrors.workstationId) {
            setLoading(false);
            return;
        }

        login(auth, "/").catch((error: Error) => {
            setLoading(false);
            notify(error?.message ?? "ra.auth.sign_in_error", {type: "warning"});
        });
    };

    const validate = (values: LoginFormValues) => {
        const validationErrors: LoginFormValues = {};
        if (!values.username) {
            validationErrors.username = translate("ra.validation.required");
        }
        if (!values.password) {
            validationErrors.password = translate("ra.validation.required");
        }
        if (!values.workstationId) {
            validationErrors.workstationId = translate("ra.validation.required");
        }
        return validationErrors;
    };

    return (
        <Paper>
            <form onSubmit={handleSubmit} noValidate>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "100vh",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        background: `url(${Rega1_Background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}>

                    <Card
                        sx={{
                            maxWidth: 450,
                            minWidth: 300,
                            marginTop: "6em"
                        }}
                    >
                        <Box
                            sx={{
                                margin: "1rem",
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <RegaSvgIcon/>
                        </Box>
                        <Box
                            sx={{
                                marginTop: "1rem",
                                display: "flex",
                                justifyContent: "center",
                                color: "palette.grey[500]"
                            }}
                        >
                            Digitales Aufgebot
                        </Box>
                        <Box
                            sx={{
                                padding: "1rem"
                            }}
                            display="flex"
                            flexDirection="column"
                        >
                            <TextField
                                sx={{
                                    "& .MuiInputBase-input": {
                                        height: "auto"
                                    }
                                }} // this sx height fix should not be necessary, but it is...
                                autoFocus
                                error={!!errors.username}
                                fullWidth
                                // value={username}
                                onChange={e => setUsername(e.target.value)}
                                id="username"
                                label={translate("ra.auth.username")}
                                autoComplete="username"
                                defaultValue=""
                                placeholder={errors.username}/>
                            <TextField
                                sx={{
                                    "& .MuiInputBase-input": {
                                        height: "auto"
                                    }
                                }} // this sx height fix should not be necessary, but it is...
                                type="password"
                                error={!!errors.password}
                                fullWidth
                                // value={password}
                                onChange={e => setPassword(e.target.value)}
                                id="password"
                                label={translate("ra.auth.password")}
                                autoComplete="current-password"
                                defaultValue=""
                                placeholder={errors.password}/>
                            <TextField
                                select
                                error={!!errors.workstationId}
                                fullWidth
                                // value={workstationId}
                                onChange={e => setWorkstationId(e.target.value)}
                                id="workstation-id"
                                label={translate("da.workstation")}
                                defaultValue=""
                                placeholder={errors.workstationId}
                            >
                                {workstationClientIdPairs.map(({workstation, workstationId: wsId}) =>
                                    <MenuItem key={workstation} value={wsId}>{workstation}</MenuItem>)}
                            </TextField>
                        </Box>
                        <CardActions
                            sx={{
                                padding: "0 1rem 1rem 1rem"
                            }}
                        >
                            <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                                {loading && (
                                    <Box display="flex" alignItems="center" justifyItems="center" pr={8}>
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    </Box>
                                )}
                                {translate("ra.auth.sign_in")}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification/>
                </Box>
            </form>
        </Paper>
    );
};

const CustomLogin = (props: any) => (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={props.theme}>
            <Login/>
        </ThemeProvider>
    </StyledEngineProvider>
);

export default CustomLogin;
