import DownloadIcon from "@mui/icons-material/GetApp";
import getUserLocale from "get-user-locale";
import jsonexport from "jsonexport";
import jsonExport from "jsonexport/dist";
import * as React from "react";
import { MouseEventHandler, useCallback } from "react";
import {
    Button,
    downloadCSV,
    ExportButtonProps,
    useDataProvider,
    useListContext,
    useNotify,
    useResourceContext
} from "react-admin";
import { getListPaged } from "../dataProvider/dataProvider";
import fetchRelatedRecordsPaged from "../dataProvider/fetchRelatedRecordsPaged";

/**
 * if maxPages === undefined || maxPages < 0: returns all results from all pages
 */
const PagedExportButtonWithFilter: React.FC<PagedExportButtonWithFilterProps> = props => {
    const {
        maxPages,
        perPage = 1000,
        onClick,
        label = "ra.action.export",
        icon = <DownloadIcon/>,
        exporter: customExporter,
        ...rest
    } = props;
    const {
        filterValues,
        sort,
        exporter: exporterFromContext,
        total
    } = useListContext(props);

    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
            getListPaged(resource, {maxPages, perPage, sort, filter: filterValues})
                .then(result => {
                    exporter && exporter(result, fetchRelatedRecordsPaged(perPage), dataProvider, resource);
                })
                .catch(error => {
                    console.error(error);
                    notify("ra.notification.http_error", {type: "warning"});
                });
            if (typeof onClick === "function") {
                onClick(event);
            }
        },
        [
            dataProvider,
            exporter,
            filterValues,
            maxPages,
            perPage,
            notify,
            onClick,
            resource,
            sort
        ]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            {...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
};

type PagedExportButtonWithFilterProps =
    Omit<ExportButtonProps, "maxResults" | "filter">
    & { maxPages?: number, perPage?: number };

const sanitizeRestProps = ({
                               filterValues: _filterValues,
                               resource: _resource,
                               ...rest
                           }: Omit<PagedExportButtonWithFilterProps, "sort" | "maxResults" | "label" | "exporter">) => rest;

const listSeparator = getUserLocale().startsWith("en") ? "," : ";";

export const csvExport =
    (json: object | object[], userOptions: jsonexport.UserOptionsWithHandlers, filename: string) => {
        jsonExport(json,
            {rowDelimiter: listSeparator, ...userOptions},
            (_err, csv) => downloadCSV(csv, filename)
        );
    };

export default PagedExportButtonWithFilter;
