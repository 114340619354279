import { merge } from "lodash-es";
import polyglotI18nProvider from "ra-i18n-polyglot";
import raLanguageGerman from "ra-language-german";
import { TranslationMessages } from "react-admin";
import raMessagesGerman from "./raMessagesGerman";
import regaDaMessagesGerman from "./regaDaMessagesGerman";

const messages: { [locale in string]: TranslationMessages } = {de: merge(raLanguageGerman, raMessagesGerman, regaDaMessagesGerman)};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "de", {allowMissing: false});

export default i18nProvider;
