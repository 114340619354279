import { merge } from "lodash-es";
import { defaultTheme } from "react-admin";

// Colors
export const daBlack = "#3f3f3f";
// noinspection JSUnusedGlobalSymbols
export const daBlackLight = "#383838";
export const daWhite = "#ffffff";
export const daRedRegular = "#ed0000";
export const daRedDark = "#b10000";
export const daBlueRegular = "#598ab3";
export const daBlueMedium = "#b8c3cc";
export const daBlueLight = "#e1e6ea";
export const daBlueSuperlight = "#f9fafb";
export const daGreen = "#62c370";
export const daOrange = "#f79000";
// noinspection JSUnusedGlobalSymbols
export const daPurple = "#4f3cc9";
export const daGray = "#808080";

const typography = {
    fontFamily: "Montserrat",
    h1: {
        fontSize: "1.625rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daWhite
    },
    h2: {
        fontSize: "1.375rem",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daBlack
    },
    h3: {
        fontSize: "1.125rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daBlack
    },
    h4: {
        fontSize: "0.875rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: "normal",
        color: daBlack
    },
    h5: {
        fontSize: "0.875rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: daBlack
    },
    h6: {
        fontSize: "0.875rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: daBlueMedium
    },
    subtitle1: {
        fontSize: "0.75rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daBlack
    },
    subtitle2: {
        fontSize: "0.75rem",
        fontWeight: 700,
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        color: daBlack
    },
    body1: {
        fontSize: "0.75rem",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daBlack
    },
    body2: {
        fontSize: "0.75rem",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: 2,
        letterSpacing: "normal",
        color: daBlack
    },
    caption: {
        fontSize: "0.75rem",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: 1.5,
        letterSpacing: "normal",
        color: daGray
    }
};

const palette = {
    primary: {
        main: daBlueRegular,
        contrastText: daWhite
    },
    secondary: {
        main: daRedRegular,
        contrastText: daWhite
    },
    success: {
        main: daGreen,
        contrastText: daWhite
    },
    error: {
        main: daRedRegular,
        contrastText: daWhite
    },
    warning: {
        main: daOrange,
        contrastText: daWhite
    },
    info: {
        main: daBlueMedium,
        contrastText: daBlack
    },
    text: {
        primary: daBlack,
        secondary: daBlueMedium,
        disabled: daBlueLight
    },
    background: {
        default: daBlueSuperlight,
        paper: daWhite
    }
};

const components = {
    MuiAutocomplete: {
        styleOverrides: {
            root: {
                width: "20rem"
            }
        }
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                width: "20rem"
            }
        }
    },
    RaDatagrid: {
        styleOverrides: {
            root: {
                "& .RaDatagrid-headerCell": {
                    fontWeight: 700
                }
            }
        }
    },
    RaShow: {
        styleOverrides: {
            root: {
                "& .RaShow-main": {
                    "& .MuiPaper-root": {
                        padding: "1rem"
                    }
                }
            }
        }
    },
    MuiTypography: {
        styleOverrides: {
            colorPrimary: {
                color: daBlack
            },
            colorSecondary: {
                color: daWhite
            },
            colorTextPrimary: {
                color: daBlack
            },
            colorTextSecondary: {
                color: daBlueMedium
            }
        }
    }
};

export const theme = merge({}, defaultTheme, {
    typography: typography,
    palette: palette,
    spacing: (factor: number) => `${0.0625 * factor}rem`,
    components: components
});
