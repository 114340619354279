import Typography from "@mui/material/Typography";
import { flatMap, groupBy, keyBy, orderBy, values } from "lodash-es";
import * as React from "react";
import {
    AutocompleteInput,
    Datagrid,
    Exporter,
    GetListResult,
    List,
    ListProps,
    Pagination,
    RaRecord,
    ReferenceInput,
    ReferenceManyField,
    Show,
    SimpleShowLayout,
    Translate,
    useTranslate
} from "react-admin";
import { csvExport } from "../customComponents/PagedExportButtonWithFilter";
import RecordLinkField from "../customComponents/RecordLinkField";
import { ORDER, Props2String, RequiredOnly, StatsWithExportListActions, toDeCHLocaleString } from "./Common";
import { RescueVehicle, RescueVehiclesResource } from "./RescueVehicles";
import { ShiftStats, ShiftStatsByUserResource, ShiftStatsDatagrid, ShiftStatsProps } from "./ShiftStats";
import { User, user2NameString, UserProps, UsersResource } from "./Users";

export const ReadinessByUserResource = "readiness/user";
export const ReadinessByUser2ShiftStatsResource = `${ReadinessByUserResource}/shifts`;

export const ReadinessByUserList: React.FC<ListProps> = () => {
    const translate = useTranslate();
    const filters = readinessByUserFilters(translate);
    return (
        <List perPage={25} empty={false}
              filters={filters}
              exporter={readinessByUserExporter}
              sort={{field: ReadinessByUserProps.name, order: ORDER.ASC}}
              actions={<StatsWithExportListActions filters={filters}/>}
        >
            <Datagrid
                bulkActionButtons={false}
                rowStyle={() => ({height: "2.25rem"})}
                rowClick="show"
            >
                <RecordLinkField source={ReadinessByUserProps.name} linkResource={UsersResource}/>
            </Datagrid>
        </List>
    );
};

const readinessByUserFilters: (translate: Translate) => JSX.Element[] = (translate) => {
    const field2LabelAndSource = (field: keyof ReadinessByUserFilterFields) => ({
        label: translate(`da.stats.readiness.user.filter.${field}`),
        source: field,
        style: {minWidth: "10rem", marginLeft: "4px", marginRight: "4px"}
    });

    return [
        <ReferenceInput
            {...field2LabelAndSource(ReadinessByUserFilterFieldsProps.id)}
            reference={UsersResource}
            sort={{field: UserProps.id, order: ORDER.ASC}}
            alwaysOn
        >
            <AutocompleteInput optionText={user2NameString}/>
        </ReferenceInput>
    ];
};

const readinessByUserExporter: Exporter = (listResult: GetListResult<ReadinessByUser>, fetchRelatedRecords) => {
    return Promise.all([
            listResult.data,
            fetchRelatedRecords(listResult.data, ReadinessByUserProps.id, ReadinessByUser2ShiftStatsResource) as PromiseLike<ShiftStats[]>,
            fetchRelatedRecords(listResult.data, ReadinessByUserProps.id, UsersResource) as PromiseLike<User[]>
        ])
        .then(([allReadinessByUser, relatedShiftStats, relatedUsers]) => {
            return Promise.all([
                allReadinessByUser,
                relatedShiftStats,
                relatedUsers,
                fetchRelatedRecords(values(relatedShiftStats), ShiftStatsProps.rescueVehicleId, RescueVehiclesResource) as PromiseLike<RescueVehicle[]>
            ]);
        })
        .then(([allReadinessByUser, relatedShiftStats, relatedUsers, relatedRescueVehicles]) => {
            const userId2User = keyBy(relatedUsers, "id");
            const userId2Shifts = groupBy(relatedShiftStats, "userId");
            const id2RescueVehicle = keyBy(relatedRescueVehicles, "id");

            const allReadinessByUserForExport = orderBy(
                flatMap(allReadinessByUser, readinessByUser => {
                    // do not include id, name
                    const {
                        id: userId,
                        name,
                        ...readinessByUserRest
                    } = readinessByUser;

                    return flatMap(userId2Shifts[userId], shiftStats => {
                        // do not include id, roleId, rescueVehicle, startedAt, finishedAt
                        const {
                            id: shiftId,
                            startedAt,
                            finishedAt,
                            ...shiftStatsRest
                        } = shiftStats;

                        const user = userId2User[userId];
                        const rescueVehicle = id2RescueVehicle[shiftStats.rescueVehicleId];

                        return {
                            ...readinessByUserRest,
                            ...shiftStatsRest,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            shiftId,
                            rescueVehicleName: rescueVehicle.name,
                            startedAt: toDeCHLocaleString(startedAt),
                            finishedAt: toDeCHLocaleString(finishedAt)
                        } as ReadinessByUserForExport;
                    });
                }),
                [ReadinessByUserForExportProps.userId, ReadinessByUserForExportProps.shiftId],
                ["asc", "desc"]
            );

            csvExport(
                allReadinessByUserForExport,
                {headers: values(ReadinessByUserForExportProps)},
                "statistiken_bereitschaft_benutzer"
            );
        });
};

const ReadinessByUserExpandShiftsLabel = () => {
    const translate = useTranslate();
    return (
        <Typography variant="h3" gutterBottom>{translate("da.stats.readiness.expand.label.shifts")}</Typography>
    );
};

export const ReadinessByUserShow = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceManyField
                pagination={<Pagination/>}
                source={ReadinessByUserProps.id}
                reference={ShiftStatsByUserResource}
                target={ShiftStatsProps.id}
                sort={{field: ShiftStatsProps.startedAt, order: ORDER.DESC}}
                label={<ReadinessByUserExpandShiftsLabel/>}
                /*sx={{
                    display: "flex",
                    "& > div": {
                        flex: "1 1 auto"
                    }
                }}*/
            >
                <ShiftStatsDatagrid withVehicle={true}/>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

type ReadinessByUser = RaRecord & {
    id: number,
    name: string
}

const ReadinessByUserProps: Props2String<ReadinessByUser> = {
    id: "id",
    name: "name"
};

type ReadinessByUserForExport =
    Omit<RequiredOnly<ReadinessByUser>, "id" | "name"> &
    Omit<RequiredOnly<ShiftStats>, "id" | "userName" | "startedAt" | "finishedAt"> & {
    firstName: string,
    lastName: string,
    shiftId: number;
    rescueVehicleName: string;
    startedAt: string;
    finishedAt: string;
};

const ReadinessByUserForExportProps: Props2String<ReadinessByUserForExport> = {
    userId: "userId",
    firstName: "firstName",
    lastName: "lastName",
    shiftId: "shiftId",
    rescueVehicleId: "rescueVehicleId",
    rescueVehicleName: "rescueVehicleName",
    roleId: "roleId",
    roleTitle: "roleTitle",
    deviceId: "deviceId",
    deviceName: "deviceName",
    startedAt: "startedAt",
    finishedAt: "finishedAt"
};

type ReadinessByUserFilterFields = {
    id: string;
};

const ReadinessByUserFilterFieldsProps: Props2String<ReadinessByUserFilterFields> = {
    id: "id"
};
