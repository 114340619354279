import { flatMap, keyBy, orderBy, values } from "lodash-es";
import * as React from "react";
import {
    Datagrid,
    DateField,
    DateTimeInput,
    Exporter,
    GetListResult,
    List,
    NumberField,
    RaRecord,
    ReferenceField,
    TextField,
    TextInput,
    Translate,
    useTranslate
} from "react-admin";
import { csvExport } from "../customComponents/PagedExportButtonWithFilter";
import RecordLinkField from "../customComponents/RecordLinkField";
import {
    dateTimeOptionsDeCH,
    ORDER,
    Props2String,
    RequiredOnly,
    StatsWithExportListActions,
    toDeCHLocaleString,
    viewRedirect
} from "./Common";
import { RescueVehicle, RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";
import { User, UsersResource } from "./Users";

export const NotificationStatsResource = "stats/notifications";

export const NotificationStatsList = () => {
    const translate = useTranslate();
    const filters = notificationStatsFilters(translate);
    return (
        <List perPage={25} empty={false}
              filters={filters}
              exporter={notificationStatsExporter}
              sort={{field: NotificationStatsProps.triggeredAt, order: ORDER.DESC}}
              actions={<StatsWithExportListActions filters={filters}/>}
        >
            <Datagrid
                bulkActionButtons={false}
                rowStyle={() => ({height: "2.25rem"})}
            >
                <NumberField source={NotificationStatsProps.id}/>
                <RecordLinkField source={NotificationStatsProps.userName} idProp={NotificationStatsProps.userId}
                                 linkResource={UsersResource}/>
                <TextField source={NotificationStatsProps.deviceName}/>
                <ReferenceField
                    source={NotificationStatsProps.rescueVehicleId}
                    reference={RescueVehiclesResource}
                    link={viewRedirect.show}
                >
                    <TextField source={RescueVehicleProps.name}/>
                </ReferenceField>
                <TextField source={NotificationStatsProps.elsEventId}/>
                <TextField source={NotificationStatsProps.elsDispatchId}/>
                <DateField source={NotificationStatsProps.triggeredAt} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
                <DateField source={NotificationStatsProps.serverReceivedAt} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
                <TextField source={NotificationStatsProps.serverReceivedAtRTT}/>
                <DateField source={NotificationStatsProps.serverReadAt} showTime locales="de-CH"
                           options={dateTimeOptionsDeCH}/>
                <TextField source={NotificationStatsProps.serverReadAtRTT}/>
            </Datagrid>
        </List>
    );
};

const notificationStatsFilters: (translate: Translate) => JSX.Element[] = (translate) => {
    const field2LabelAndSource = (field: keyof NotificationStatsFilterFields) => ({
        label: translate(`da.stats.notification.filter.${field}`),
        source: field,
        style: {minWidth: "10rem", marginLeft: "4px", marginRight: "4px"}
    });

    return [
        <TextInput
            {...field2LabelAndSource(NotificationStatsFilterFieldsProps.query)}
            alwaysOn
        />,
        <DateTimeInput {...field2LabelAndSource(NotificationStatsFilterFieldsProps.triggeredAfter)} />,
        <DateTimeInput {...field2LabelAndSource(NotificationStatsFilterFieldsProps.triggeredBefore)} />
    ];
};

const notificationStatsExporter: Exporter = (listResult: GetListResult<NotificationStats>, fetchRelatedRecords) => {
    return Promise.all([
            listResult.data,
            fetchRelatedRecords(listResult.data, NotificationStatsProps.userId, UsersResource) as PromiseLike<User[]>,
            fetchRelatedRecords(listResult.data, NotificationStatsProps.rescueVehicleId, RescueVehiclesResource) as PromiseLike<RescueVehicle[]>
        ])
        .then(([allNotificationStats, relatedUsers, relatedRescueVehicles]) => {
            const id2Users = keyBy(relatedUsers, "id");
            const id2RescueVehicle = keyBy(relatedRescueVehicles, "id");

            const allNotificationStatsForExport = orderBy(
                flatMap(allNotificationStats, notificationStats => {
                    // do not include id, triggeredAt, triggeredAt, serverReceivedAt, serverReadAt
                    const {
                        id: notificationId,
                        triggeredAt,
                        serverReceivedAt,
                        serverReadAt,
                        userName,
                        ...notificationStatsRest
                    } = notificationStats;

                    const user = id2Users[notificationStatsRest.userId];
                    const rescueVehicle = id2RescueVehicle[notificationStatsRest.rescueVehicleId];

                    return {
                        ...notificationStatsRest,
                        notificationId,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        rescueVehicleName: rescueVehicle.name,
                        triggeredAt: toDeCHLocaleString(triggeredAt),
                        serverReceivedAt: toDeCHLocaleString(serverReceivedAt),
                        serverReadAt: toDeCHLocaleString(serverReadAt)
                    } as NotificationStatsForExport;
                }),
                [NotificationStatsForExportProps.notificationId],
                ["desc"]);

            csvExport(
                allNotificationStatsForExport,
                {headers: values(NotificationStatsForExportProps)},
                "statistiken_push"
            );
        });
};

type NotificationStats = RaRecord & {
    id: number,
    userId: number,
    userName: string,
    deviceId: string,
    deviceName: string,
    rescueVehicleId: number,
    rescueVehicleName: string,
    elsEventId: string,
    elsDispatchId: string,
    shiftId: number;
    missionId: number;
    missionInfoId: number;
    triggeredAt: number,
    serverReceivedAt: number,
    serverReceivedAtRTT: number,
    serverReadAt: number,
    serverReadAtRTT: number,
}

const NotificationStatsProps: Props2String<NotificationStats> = {
    id: "id",
    userId: "userId",
    userName: "userName",
    deviceId: "deviceId",
    deviceName: "deviceName",
    rescueVehicleId: "rescueVehicleId",
    rescueVehicleName: "rescueVehicleName",
    elsEventId: "elsEventId",
    elsDispatchId: "elsDispatchId",
    shiftId: "shiftId",
    missionId: "missionId",
    missionInfoId: "missionInfoId",
    triggeredAt: "triggeredAt",
    serverReceivedAt: "serverReceivedAt",
    serverReceivedAtRTT: "serverReceivedAtRTT",
    serverReadAt: "serverReadAt",
    serverReadAtRTT: "serverReadAtRTT"
};

type NotificationStatsForExport =
    Omit<RequiredOnly<NotificationStats>, "id" | "userName" | "triggeredAt" | "serverReceivedAt" | "serverReadAt">
    & {
    notificationId: number;
    firstName: string;
    lastName: string;
    triggeredAt: string;
    serverReceivedAt: string;
    serverReadAt: string;
};

const NotificationStatsForExportProps: Props2String<NotificationStatsForExport> = {
    notificationId: "notificationId",
    userId: "userId",
    firstName: "firstName",
    lastName: "lastName",
    deviceId: "deviceId",
    deviceName: "deviceName",
    rescueVehicleId: "rescueVehicleId",
    rescueVehicleName: "rescueVehicleName",
    elsEventId: "elsEventId",
    elsDispatchId: "elsDispatchId",
    shiftId: "shiftId",
    missionId: "missionId",
    missionInfoId: "missionInfoId",
    triggeredAt: "triggeredAt",
    serverReceivedAt: "serverReceivedAt",
    serverReceivedAtRTT: "serverReceivedAtRTT",
    serverReadAt: "serverReadAt",
    serverReadAtRTT: "serverReadAtRTT"
};

type NotificationStatsFilterFields = {
    query: string;
    triggeredAfter: number;
    triggeredBefore: number;
};

const NotificationStatsFilterFieldsProps: Props2String<NotificationStatsFilterFields> = {
    query: "query",
    triggeredAfter: "triggeredAfter",
    triggeredBefore: "triggeredBefore"
};
