import { filter, keyBy, map, uniq } from "lodash-es";
import { Identifier, RaRecord } from "react-admin";
import { getManyPaged } from "./dataProvider";


/**
 * Copied from ra-core/fetchRelatedRecords and adapted to a form of paging and rewritten in lodash
 *
 * Helper function for calling the dataProvider.getMany() method,
 * and getting a Promise for the records indexed by id in return.
 *
 * @example
 *     fetchRelatedRecords(dataProvider)(records, 'post_id', 'posts').then(posts =>
 *         posts.map(record => ({
 *             ...record,
 *             post_title: posts[record.post_id].title,
 *         }))
 *     );
 */
const fetchRelatedRecordsPaged = (perPage: number) => (data: RaRecord[], field: string, resource: string) =>
    getManyPaged(resource, {ids: getRelatedIds(data, field), perPage: Math.min(perPage, 200)})
        .then(({data: record}) => keyBy(record, "id"));

/**
 * Copied from ra-core/fetchRelatedRecords and rewritten in lodash
 *
 * Extracts, aggregates and deduplicates the ids of related records
 *
 * @example
 *     const books = [
 *         { id: 1, author_id: 123, title: 'Pride and Prejudice' },
 *         { id: 2, author_id: 123, title: 'Sense and Sensibility' },
 *         { id: 3, author_id: 456, title: 'War and Peace' },
 *     ];
 *     getRelatedIds(books, 'author_id'); => [123, 456]
 *
 * @example
 *     const books = [
 *         { id: 1, tag_ids: [1, 2], title: 'Pride and Prejudice' },
 *         { id: 2, tag_ids: [2, 3], title: 'Sense and Sensibility' },
 *         { id: 3, tag_ids: [4], title: 'War and Peace' },
 *     ];
 *     getRelatedIds(records, 'tag_ids'); => [1, 2, 3, 4]
 *
 * @param {Object[]} records An array of records
 * @param {string} field the identifier of the record field to use
 */
export const getRelatedIds = (records: RaRecord[], field: string): Identifier[] =>
    uniq(filter(map(records, record => record[field]), value => value != null));

export default fetchRelatedRecordsPaged;
