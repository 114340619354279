import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useRef, useState } from "react";
import { usePermissions } from "react-admin";
import { PHONE_AUTHORITY } from "../authProvider/authProvider";
import { daBlueMedium, daWhite } from "../layout/Theme";
import PhoneCallButton, { PhoneCallButtonRef } from "./PhoneCallButton";
import { MissionsStatusTitleProps } from "./VehicleStatusCard";

const MissionsStatusTitle: React.FC<MissionsStatusTitleProps> = ({vehicleName, vehicleStatus, helicopter}) => {
    const [hover, setHover] = useState(false);

    const textBgColor = vehicleStatus2TextBgColor[vehicleStatus];
    const textColor = vehicleStatus === "IDLE" ? daBlueMedium : daWhite;

    const statusBgColor = vehicleStatus2StatusBgColor[vehicleStatus];
    const backgroundClass = vehicleStatus === "IDLE" ? {
        clipPath: "polygon(80% 0%, 100% 0%, 20% 100%, 0% 100%)"
    } : {
        clipPath: "polygon(0% 0%, 100% 0%, 20% 100%, 0% 100%)"
    };

    const phoneVariant = vehicleStatus === "IDLE" ? "blue-medium" : "white";

    const {permissions} = usePermissions();
    const hasPhonePermission = permissions?.includes(PHONE_AUTHORITY);

    const phoneCallButtonRef = useRef({} as PhoneCallButtonRef);
    const handleClick = () =>
        hasPhonePermission && phoneCallButtonRef.current?.makePhoneCall();

    return (
        <Box
            key={vehicleName}
            display="grid"
            gridTemplateColumns="auto 24px"
            height="100%"
            minHeight="4rem"
        >
            <Box
                display="flex"
                flexDirection="row"
                justifyItems="space-between"
                bgcolor={textBgColor}>
                <Box
                    sx={{
                        margin: "0.25rem 0rem",
                        padding: "0rem 0.25rem 0rem 0.75rem",
                        width: "100%",
                        display: "grid",
                        gridTemplateAreas: "'name phone' 'registration .'",
                        gridTemplateColumns: "1fr 1.5rem",
                        gridColumnGap: "0.5rem",
                        gridTemplateRows: "minmax(2rem, 1fr) minmax(1.25rem, auto)",
                        border: "2px solid transparent"
                    }}
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={handleClick}>
                    <Box
                        display="grid"
                        justifyItems="start"
                        alignItems="center"
                        gridArea="name">
                        <Typography variant="h3" sx={{color: textColor}}>{vehicleName}</Typography>
                    </Box>
                    {helicopter &&
                     <Box
                         display="grid"
                         justifyItems="start"
                         alignItems="center"
                         gridArea="registration">
                         <Typography variant="caption" sx={{color: textColor}}>{helicopter.registration}</Typography>
                     </Box>
                    }
                    <Box
                        display="grid"
                        justifyItems="center"
                        alignItems="center"
                        gridArea="phone"
                    >
                        {helicopter && hasPhonePermission && hover && helicopter.phoneNumber && (
                            <PhoneCallButton
                                ref={phoneCallButtonRef}
                                phoneNumber={helicopter.phoneNumber}
                                variant={phoneVariant}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                bgcolor={statusBgColor}
                sx={backgroundClass}
                ml={-1}
                // ml={-1} to overlay the divs as the browser does not render a one pixel wide area (keeping it empty)
                // between the divs if the left div has width between XX.00 and XX.49 pixels
            />
        </Box>
    );
};

export const vehicleStatus2TextBgColor = {
    IDLE: "background.paper",
    MISSION_PENDING: "secondary.main",
    MISSION_ACKNOWLEDGED: "primary.main"
};

export const vehicleStatus2StatusBgColor = {
    IDLE: "info.main",
    MISSION_PENDING: "secondary.main",
    MISSION_ACKNOWLEDGED: "primary.main"
};

export default MissionsStatusTitle;

