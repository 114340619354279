import { MenuItemProps, TooltipProps } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, {
    cloneElement,
    forwardRef,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useState
} from "react";
import { useBasename, useSidebarState, useTranslate } from "react-admin";
import { Link, LinkProps, useMatch } from "react-router-dom";
import { daBlack, daBlueMedium, daBlueRegular } from "./Theme";

/**
 * Displays a menu item with a label and an icon - or only the icon with a tooltip when the sidebar is minimized.
 * It also handles the automatic closing of the menu on tap on mobile.
 *
 * @typedef {Object} Props the props you can use
 * @prop {string|Location} to The menu item's target. It is passed to a React Router NavLink component.
 * @prop {string|ReactNode} primaryText The menu content, displayed when the menu isn't minimized. |
 * @prop {ReactNode} leftIcon The menu icon
 *
 * Additional props are passed down to the underling MUI <MenuItem> component
 * @see https://material-ui.com/api/menu-item/#menuitem-api
 *
 * @example // You can create a custom menu component using the <DashboardMenuItem> and <MenuItemLink> components:
 *
 * // in src/Menu.js
 * import * as React from 'react';
 * import { DashboardMenuItem, MenuItemLink } from 'react-admin';
 * import BookIcon from '@mui/icons-material/Book';
 * import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
 * import PeopleIcon from '@mui/icons-material/People';
 * import LabelIcon from '@mui/icons-material/Label';
 *
 * export const Menu = () => (
 *     <div>
 *         <DashboardMenuItem />
 *         <MenuItemLink to="/posts" primaryText="Posts" leftIcon={<BookIcon />}/>
 *         <MenuItemLink to="/comments" primaryText="Comments" leftIcon={<ChatBubbleIcon />}/>
 *         <MenuItemLink to="/users" primaryText="Users" leftIcon={<PeopleIcon />}/>
 *         <MenuItemLink to="/custom-route" primaryText="Miscellaneous" leftIcon={<LabelIcon />}/>
 *     </div>
 * );
 *
 * // to use this custom menu component, pass it to a custom Layout:
 * // in src/Layout.js
 * import { Layout } from 'react-admin';
 * import { Menu } from './Menu';
 *
 * export const Layout = (props) => <Layout {...props} menu={Menu} />;
 *
 * // then, use this layout in the <Admin layout> prop:
 * // in src/App.js
 * import { Layout }  from './Layout';
 *
 * const App = () => (
 *     <Admin layout={Layout} dataProvider={simpleRestProvider("http://path.to.my.api")}>
 *         // ...
 *     </Admin>
 * );
 */
const MenuItemLink = forwardRef<any, MenuItemLinkProps>((props, ref) => {
    const {
        className,
        primaryText,
        leftIcon,
        onClick,
        tooltipProps,
        ...rest
    } = props;

    const translate = useTranslate();
    const basename = useBasename();

    const [open] = useSidebarState();
    const [hover, setHover] = useState(false);
    const handleMenuTap: MouseEventHandler<HTMLLIElement> = useCallback(
        e => {
            onClick && onClick(e);
        },
        [onClick]
    );

    // noinspection SuspiciousTypeOfGuard
    const to =
        (typeof props.to === "string" ? props.to : props.to.pathname) || "";
    const match = useMatch({path: to, end: to === `${basename}/`});
    const fillColor = hover ? daBlueRegular : match ? daBlack : daBlueMedium;

    const primaryTextTranslated = typeof primaryText === "string"
                                  ? translate(primaryText, {_: primaryText})
                                  : primaryText;

    return (
        <StyledMenuItem
            className={clsx(className, {
                [MenuItemLinkClasses.active]: !!match
            })}
            // @ts-ignore
            component={LinkRef}
            ref={ref}
            tabIndex={0}
            disableGutters={true}
            {...rest}
            onClick={handleMenuTap}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <ListItemIcon className={MenuItemLinkClasses.icon}>
                {cloneElement(leftIcon, {
                    fill: fillColor
                })}
            </ListItemIcon>
            {open && <Typography sx={{marginLeft: "0.5rem"}} variant="h4" color="inherit">
                {primaryTextTranslated}
            </Typography>}
        </StyledMenuItem>
    );
});

export type MenuItemLinkProps = LinkProps &
    MenuItemProps<"li"> & {
    leftIcon: ReactElement;
    primaryText?: ReactNode;
    tooltipProps?: TooltipProps;
};

const PREFIX = "RaMenuItemLink";

export const MenuItemLinkClasses = {
    active: `${PREFIX}-active`,
    icon: `${PREFIX}-icon`
};

const StyledMenuItem = styled(MenuItem, {
    name: PREFIX,
    overridesResolver: (_props, styles) => styles.root
})(({theme}) => ({
    color: theme.palette.text.secondary,

    [`&.${MenuItemLinkClasses.active}`]: {
        color: theme.palette.text.primary
    },

    [`& .${MenuItemLinkClasses.icon}`]: {minWidth: theme.spacing(5)}
}));

const LinkRef = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link ref={ref} {...props} />
));

export default MenuItemLink;
