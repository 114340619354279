import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { partition } from "lodash-es";
import * as React from "react";
import { ResourceDefinition, usePermissions, useResourceDefinitions, useSidebarState, useTranslate } from "react-admin";
import { SETTINGS_AUTHORITY } from "../authProvider/authProvider";
import { SettingsResource } from "../customRoutes/Settings";
import { ReactComponent as DashboardIcon } from "../images/ic-web-dashboard.svg";
import { ReactComponent as SettingsIcon } from "../images/ic-web-settings.svg";
import { envIsIntOrProd } from "../workstationSettings/workStationSettings";
import AppBar from "./AppBar";
import LogoutButton from "./LogoutButton";
import MenuItemLink from "./MenuItemLink";
import { daBlueLight, daBlueRegular } from "./Theme";

export const DRAWER_WIDTH = 300;
export const CLOSED_DRAWER_WIDTH = 48;

const CustomSidebar = () => {
    const [open, setOpen] = useSidebarState();
    const theme = useTheme();

    const {permissions} = usePermissions();

    const translate = useTranslate();
    const resourcesDefinitions = useResourceDefinitions();
    const resourcesWithList = Object.keys(resourcesDefinitions).filter(name => resourcesDefinitions[name].hasList).map(name => resourcesDefinitions[name]);
    const [resourcesStats, resourceEntities] = partition(resourcesWithList, resource => resource.name.startsWith("stats/") || resource.name.startsWith("readiness/"));

    const resource2MenuItemLink = (resource: ResourceDefinition) =>
        <MenuItemLink
            key={resource.name}
            sx={{
                marginTop: "0.375rem",
                marginBottom: "0.375rem",
                "&:hover": {
                    color: daBlueRegular
                }
            }}
            to={`/${resource.name}`}
            primaryText={translate(`resources.${resource.name}.name`)}
            leftIcon={<resource.icon/>}
        />;

    const BoxedDivider = () => <Box my={6} px={8}><Divider sx={{backgroundColor: daBlueLight}}/></Box>;

    const DashboardGroup = () =>
        <>
            <MenuItemLink
                sx={{
                    marginTop: "0.375rem",
                    marginBottom: "0.375rem",
                    "&:hover": {
                        color: daBlueRegular
                    }
                }}
                to="/"
                primaryText={translate("dashboard.name")}
                leftIcon={<DashboardIcon/>}
            />
            <BoxedDivider/>
        </>;

    const ResourcesGroup: React.FC<{
        resources: ResourceDefinition[]
    }> = ({resources}) =>
        resources.length
            ?
            <>
                {resources.map(resource2MenuItemLink)}
                <BoxedDivider/>
            </>
            :
            <></>;

    return (
        <Box
            sx={{
                position: "sticky",
                top: "1.75rem",
                margin: "0.75rem 0"
            }}
        >
            <AppBar/>
            <Drawer
                variant="permanent"
                open={open}
                PaperProps={{
                    sx: {
                        // margin: "0.75rem 0",
                        position: "relative",
                        width: open
                            ? DRAWER_WIDTH
                            : CLOSED_DRAWER_WIDTH,
                        height: "100%",
                        overflowX: "hidden",
                        transition: theme.transitions.create("width", {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen
                        }),
                        backgroundColor: "transparent",
                        borderRight: "none",
                        zIndex: "inherit"
                    }
                }}
                onClose={() => setOpen(false)}
            >
                <DashboardGroup/>
                <ResourcesGroup resources={resourceEntities}/>
                <ResourcesGroup resources={resourcesStats}/>
                {permissions?.includes(SETTINGS_AUTHORITY) && <MenuItemLink
                    sx={{
                        marginTop: "0.375rem",
                        marginBottom: "0.375rem",
                        "&:hover": {
                            color: daBlueRegular
                        }
                    }}
                    to={`/${SettingsResource}`}
                    primaryText={translate("da.routes.settings.name")}
                    leftIcon={<SettingsIcon/>}
                />}
                <LogoutButton/>
                {!envIsIntOrProd && <>
                    <BoxedDivider/>
                    <Typography variant="h4" color="inherit" sx={{
                        textOverflow: "ellipsis",
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}>
                        {process.env.REACT_APP_RELEASE_ENV} {process.env.REACT_APP_VERSION}
                    </Typography>
                </>}
            </Drawer>
        </Box>
    );
};

export default CustomSidebar;
