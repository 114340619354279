import { get } from "lodash-es";
import * as React from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DeleteWithConfirmButton,
    Edit,
    EditButton,
    List,
    ListButton,
    NumberField,
    NumberInput,
    RaRecord,
    required,
    RowClickFunction,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext
} from "react-admin";
import { SaveAndDeleteWithConfirmToolbar } from "../customComponents/CustomToolbars";
import { CreateViewActions, EditViewActions, ListViewActions } from "../customComponents/CustomTopToolbars";
import { dateTimeOptionsDeCH, ORDER, Props2String, viewRedirect } from "./Common";

export const RolesResource = "roles";

const SourceDependentEditButton: React.FC<{ source: string, label: string | boolean }> =
    ({source}) => {
        const record = useRecordContext();
        return (
            record && get(record, source) && <EditButton sx={{
                padding: "0px",
                verticalAlign: "inherit",
                minWidth: 0
            }} label="" record={record}/>
        );
    };

const SourceDependentDeleteWithConfirmButton: React.FC<{ source: string, label: string | boolean }> =
    ({source}) => {
        const record = useRecordContext();
        return (
            record && get(record, source)
            && <DeleteWithConfirmButton sx={{
                padding: "0px",
                verticalAlign: "inherit",
                minWidth: 0
            }} label="" record={record} translateOptions={{title: record.title}}/>
        );
    };

const roleRowClick: RowClickFunction = (_id, _resource, record) =>
    (record as Role).isEditable ? viewRedirect.edit : viewRedirect.show;

export const RoleList = () => (
    <List perPage={25} exporter={false} empty={false}
          sort={{field: RoleProps.id, order: ORDER.ASC}}
          actions={<ListViewActions/>}
    >
        <Datagrid bulkActionButtons={false} rowClick={roleRowClick}
                  rowStyle={() => ({height: "2.25rem"})}>
            <TextField source={RoleProps.title}/>
            <NumberField source={RoleProps.priority}/>
            <BooleanField source={RoleProps.isSecondary}/>
            <BooleanField source={RoleProps.canAcknowledgeMission}/>
            <SourceDependentEditButton source={RoleProps.isEditable} label={false}/>
            <SourceDependentDeleteWithConfirmButton source={RoleProps.isEditable} label={false}/>
        </Datagrid>
    </List>
);

const RoleShowViewActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            {record?.isEditable && <EditButton/>}
            <ListButton/>
        </TopToolbar>
    );
};

export const RoleShow = () => (
    <Show actions={<RoleShowViewActions/>}>
        <SimpleShowLayout>
            <TextField source={RoleProps.title}/>
            <NumberField source={RoleProps.priority}/>
            <BooleanField source={RoleProps.isSecondary}/>
            <BooleanField source={RoleProps.canAcknowledgeMission}/>
            <DateField source={RoleProps.createdAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
            <DateField source={RoleProps.lastModified} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
        </SimpleShowLayout>
    </Show>
);

export const RoleEdit = () => {
    return (
        <Edit actions={<EditViewActions/>} redirect={viewRedirect.list}>
            <SimpleForm
                warnWhenUnsavedChanges
                toolbar={
                    <SaveAndDeleteWithConfirmToolbar
                        confirmContent="da.roles.delete.confirm.text"
                        translateOptionsFunc={(record) => ({name: record.title})}/>
                }
            >
                <TextInput source={RoleProps.title} validate={required()}/>
                <NumberInput source={RoleProps.priority} validate={required()}/>
                <BooleanInput source={RoleProps.isSecondary}/>
                <BooleanInput source={RoleProps.canAcknowledgeMission}/>
            </SimpleForm>
        </Edit>
    );
};

export const RoleCreate = () => (
    <Create actions={<CreateViewActions/>} redirect={viewRedirect.list}>
        <SimpleForm warnWhenUnsavedChanges>
            <TextInput source={RoleProps.title} validate={required()}/>
            <NumberInput source={RoleProps.priority} validate={required()}/>
            <BooleanInput source={RoleProps.isSecondary}/>
            <BooleanInput source={RoleProps.canAcknowledgeMission}/>
        </SimpleForm>
    </Create>
);

export type Role = RaRecord & {
    id: number,
    title: string,
    isSecondary: boolean,
    canAcknowledgeMission: boolean,
    isEditable: boolean,
    priority: number,
    createdAt: number,
    lastModified: number
};

export const RoleProps: Props2String<Role> = {
    id: "id",
    title: "title",
    isSecondary: "isSecondary",
    canAcknowledgeMission: "canAcknowledgeMission",
    isEditable: "isEditable",
    priority: "priority",
    createdAt: "createdAt",
    lastModified: "lastModified"
};
