import Box from "@mui/material/Box";
import { filter, flatten, isEmpty, map, values } from "lodash-es";
import * as React from "react";
import { useNotify } from "react-admin";
import { makeConference } from "../dataProvider/makeCall";
import { ReactComponent as PhoneConferenceIconBlueMedium } from "../images/ic-conf-blue-medium.svg";
import { CrewMemberByRole } from "./VehicleStatuses";

const PhoneConferenceButton = ({phoneNumbers, children}: PhoneConferenceButtonProps) => {
    const notify = useNotify();
    const makeConferenceCall = () => {
        const clientId = sessionStorage.getItem("workstationId");
        if (clientId !== null) {
            makeConference(phoneNumbers, clientId)
                .then(_ => {
                    notify("da.conf.connection.start", {type: "info", autoHideDuration: 3000});
                })
                .catch(_ => {
                    notify("da.conf.connection.failed", {type: "warning"});
                });
        } else {
            console.error("Workstation id missing");
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                "&:hover": {
                    cursor: "pointer"
                }
            }}
            onClick={makeConferenceCall}
        >
            <PhoneConferenceIconBlueMedium width={48} height={48}/>
            {children}
        </Box>
    );
};

export type PhoneConferenceButtonProps = {
    phoneNumbers: string[]
    children?: React.ReactNode
};

export const extractCrewPhoneNumbers = (crewByRole: CrewMemberByRole) => filter(map(flatten(values(crewByRole)), cm => cm.phoneNumber), phoneNumber => !isEmpty(phoneNumber));

export default PhoneConferenceButton;
