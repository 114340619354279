import * as React from "react";
import { Datagrid, DatagridProps, DateField, NumberField, RaRecord, ReferenceField, TextField } from "react-admin";
import RecordLinkField from "../customComponents/RecordLinkField";
import { dateTimeOptionsDeCH, Props2String, viewRedirect } from "./Common";
import { RescueVehicleProps, RescueVehiclesResource } from "./RescueVehicles";
import { RolesResource } from "./Roles";
import { UsersResource } from "./Users";

const ShiftStatsResource = "stats/shifts";
export const ShiftStatsByUserResource = `${ShiftStatsResource}/user`;
export const ShiftStatsByRescueVehicleResource = `${ShiftStatsResource}/rescuevehicle`;

type ShiftStatsDatagridProps = DatagridProps & { withUser?: boolean, withVehicle?: boolean };

export const ShiftStatsDatagrid: React.FC<ShiftStatsDatagridProps> = ({withUser, withVehicle}) => (
    <Datagrid
        bulkActionButtons={false}
        rowStyle={() => ({height: "2.25rem"})}
    >
        <NumberField source={ShiftStatsProps.id}/>
        {withUser && <RecordLinkField
            source={ShiftStatsProps.userName}
            idProp={ShiftStatsProps.userId}
            linkResource={UsersResource}
        />}
        {withVehicle && <ReferenceField
            source={ShiftStatsProps.rescueVehicleId}
            reference={RescueVehiclesResource}
            link={viewRedirect.show}
        >
            <TextField source={RescueVehicleProps.name}/>
        </ReferenceField>}
        <RecordLinkField source={ShiftStatsProps.roleTitle} idProp={ShiftStatsProps.roleId}
                         linkResource={RolesResource}/>
        <TextField source={ShiftStatsProps.deviceName}/>
        <DateField source={ShiftStatsProps.startedAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
        <DateField source={ShiftStatsProps.finishedAt} showTime locales="de-CH" options={dateTimeOptionsDeCH}/>
    </Datagrid>
);

export type ShiftStats = RaRecord & {
    id: number;
    rescueVehicleId: number;
    roleId: number;
    roleTitle: string;
    userId: number;
    userName: string;
    deviceId: string;
    deviceName: string;
    startedAt: number;
    finishedAt: number;
};

export const ShiftStatsProps: Props2String<ShiftStats> = {
    id: "id",
    rescueVehicleId: "rescueVehicleId",
    roleId: "roleId",
    roleTitle: "roleTitle",
    userId: "userId",
    userName: "userName",
    deviceId: "deviceId",
    deviceName: "deviceName",
    startedAt: "startedAt",
    finishedAt: "finishedAt"
};
