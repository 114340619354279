import * as React from "react";
import { EditButton, ListButton, ShowButton, TopToolbar } from "react-admin";
import CreateButtonNoFab from "./CreateButtonNoFab";

export const ListViewActions = () => (
    <TopToolbar>
        <CreateButtonNoFab/>
    </TopToolbar>
);

export const CreateViewActions = () => (
    <TopToolbar>
        <ListButton/>
    </TopToolbar>
);

export const EditViewActions = () => (
    <TopToolbar>
        <ShowButton/>
        <ListButton/>
    </TopToolbar>
);

export const ShowViewActions = () => (
    <TopToolbar>
        <EditButton/>
        <ListButton/>
    </TopToolbar>
);
