const raMessagesGerman = {
    ra: {
        action: {
            close: "Schliessen",
            expand: "Ausklappen"
        },
        notification: {
            logged_out: "Ihre Sitzung ist beendet, bitte verbinden Sie sich erneut."
        },
        message: {
            unsaved_changes: "Einige deiner Änderungen wurden nicht gespeichert. Bist du sicher, dass du diese ignorieren willst?"
        },
        page: {
            loading: "Laden..."
        }
    }
};

export default raMessagesGerman;
